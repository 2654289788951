/* eslint no-await-in-loop: 0 */
// TODO: remove eslint-disable
import axios from 'axios';

import { isSecure, logger } from '@canalplus/oneplayer-utils';

import * as Utils from './utils';
import VASTResponse from './VASTResponse';
import VMAPResponse from './VMAPResponse';

export default class Parser {
  constructor() {
    this.trackingEvents = [];
  }

  getTrackingEvents(adType, adStartTime) {
    return (
      this.trackingEvents.find(
        (elem) => elem.adType === adType && elem.adStartTime === adStartTime,
      )?.events || []
    );
  }

  setTrackingEvents(trackingEvents) {
    this.trackingEvents = trackingEvents;
  }

  // TODO: grosse refacto
  async onComplete(response) {
    const newResponse = response;

    for (let i = 0; i < response.ads.length; i += 1) {
      const ad = response.ads[i];
      const currentIndex = response.ads.indexOf(ad);

      if ('nextWrapperURL' in ad) {
        // Remove protocol from given url if isSecure
        const wrapperURL = isSecure
          ? ad.nextWrapperURL.replace(/^\w+:/, '')
          : ad.nextWrapperURL;

        try {
          const { data: vast } = await axios.get(wrapperURL, {
            responseType: 'document',
          });

          if (!vast) {
            // vast can sometimes be null
            Utils.trackErrorWithCode(ad.errorURLTemplates, 303);
          } else {
            const newParsedVast = await this.parseVast(vast);
            const wrappedResponse = newParsedVast[0].ad;

            // TODO: check if parsing give a new array of VASTAd
            // and track an error for all parents' URLs (multi Wrapper case)
            // ### Error 303: No VAST response after one or more Wrappers
            if (!wrappedResponse.ads.length) {
              // TODO: We used to throw a PARSING_ADS_ERROR here, causing
              // no ads to be played even when one ad in response.ads was valid.
              // A better error handling might be required.
              // throw PARSING_ADS_ERROR;
              Utils.trackErrorWithCode(ad.errorURLTemplates, 303);
            } else {
              newResponse.setErrorURLTemplates(
                wrappedResponse.errorURLTemplates,
              );

              // Remove old version of VASTAd
              newResponse.ads.splice(currentIndex, 1);

              for (let j = 0; j < wrappedResponse.ads.length; j += 1) {
                const wrappedAd = wrappedResponse.ads[j];
                wrappedAd.setErrorURLTemplates(ad.errorURLTemplates);
                wrappedAd.setImpressionURLTemplates(ad.impressionURLTemplates);
                wrappedAd.setExtensions(ad.extensions);

                const newCreatives = [];
                wrappedAd.creatives.forEach((creative) => {
                  const newCreative = creative;

                  switch (creative.type) {
                    case 'linear': {
                      const linear = ad.getLinearCreative();

                      if (creative.trackingEvents) {
                        newCreative.setTrackingEvents(linear.trackingEvents);
                      }

                      const { videoClickTrackingURLTemplates } = linear;
                      newCreative.setVideoClickTrackingURLTemplates(
                        videoClickTrackingURLTemplates,
                      );
                      break;
                    }

                    default:
                  }

                  newCreatives.push(newCreative);
                });

                wrappedAd.creatives = newCreatives;

                // Add at the same place the new version of VASTAd
                newResponse.ads.splice(currentIndex, 0, wrappedAd);
              }
            }
          }
        } catch (error) {
          logger.error(
            `OnePlayer Ads > try to call ${wrapperURL} but no response`,
            error,
          );

          // ### Error 303: No VAST response after one or more Wrappers
          Utils.trackErrorWithCode(ad.errorURLTemplates, 303);

          // Remove definitively the VASTAd if there's an error
          newResponse.ads.splice(currentIndex, 1);
        }
      }
    }

    return newResponse;
  }

  async parseVast(xml) {
    const newResponse = [];
    const parsedVast = new VASTResponse(xml.documentElement);
    const newParsedVast = await this.onComplete(parsedVast);
    newResponse.push({ ad: newParsedVast });

    return newResponse;
  }

  async parseVmap(xml) {
    // Start by getting all VASTS from VMAP
    const response = new VMAPResponse(xml.documentElement);
    const { vasts, trackingEvents } = response;
    const newResponse = [];

    // Set all trackingEvents from VMAP
    this.setTrackingEvents(trackingEvents);

    // Parse each VAST in VMAP
    for (let v = 0, len = vasts.length; v < len; v += 1) {
      const { vast, adType, adStartTime } = vasts[v];
      const parsedVast = new VASTResponse(vast);
      const newParsedVast = await this.onComplete(parsedVast);
      // We need to push with adType in order for our ads
      // to be properly placed within our state tree
      newResponse.push({ ad: newParsedVast, adType, adStartTime });
    }

    return newResponse;
  }
}
