import { connect } from 'react-redux';
import { breakpointSelector, heightSelector, isMobilePortraitLayoutSelector, isPlaystationSelector, isSidepanelOpenSelector, isTvPlayerSelector, mobilePortraitVideoHeightSelector, mobilePortraitVideoTopSelector, panelSelector, playerUidSelector, widthSelector, } from '@oneplayer/selectors';
import VideoWrapper from '../../presentational/layout/videowrapper/VideoWrapper';
var mapStateToProps = function (state) { return ({
    uid: playerUidSelector(state),
    isMobilePortraitLayout: isMobilePortraitLayoutSelector(state),
    isSidepanelOpen: isSidepanelOpenSelector(state),
    frameHeight: heightSelector(state),
    frameWidth: widthSelector(state),
    currentBreakpoint: breakpointSelector(state),
    mobilePortraitVideoHeight: mobilePortraitVideoHeightSelector(state),
    mobilePortraitVideoTop: mobilePortraitVideoTopSelector(state),
    isTvPlayer: isTvPlayerSelector(state),
    isPlaystation: isPlaystationSelector(state),
    tvPlayerPanel: panelSelector(state),
}); };
export default connect(mapStateToProps)(VideoWrapper);
