import { actionTypes } from '@canalplus/oneplayer-constants';
var ERROR_SHOW = actionTypes.ERROR_SHOW, ERROR_RESET = actionTypes.ERROR_RESET;
function isLocalizedError(error) {
    return 'category' in error;
}
export function showErrorAction(params) {
    var code = params.code, options = params.options;
    if (isLocalizedError(params)) {
        return {
            type: ERROR_SHOW,
            payload: { code: code, options: options, category: params.category },
        };
    }
    return {
        type: ERROR_SHOW,
        payload: { code: code, options: options, message: params.message },
    };
}
export function resetErrorAction() {
    return {
        type: ERROR_RESET,
    };
}
