import { createSelector } from 'reselect';
import { BusinessTypes } from '@canalplus/oneplayer-constants';
import { milliSecondsToSeconds, toDate } from '@canalplus/oneplayer-utils';
import { createDeepEqualSelector } from '../../utils/reselectSetup';
import { noSeekDurationForAdAfterBeginningSelector, noSeekDurationForAdAtBeginningSelector, } from './channelSelector';
import { currentTimeSelector, liveTimeSelector } from './coreSelector';
import { dataSelector, isLiveSelector } from './dataSelector';
var timeCodeTypes = BusinessTypes.timeCodeTypes;
export var programSelector = function (state) {
    return state.program;
};
/**
 * @returns the current program
 */
export var currentProgramSelector = createDeepEqualSelector(programSelector, function (program) { return program.currentProgram; });
/**
 * @returns the current program picture
 */
export var currentProgramPictureSelector = createSelector(currentProgramSelector, function (currentProgram) { return currentProgram === null || currentProgram === void 0 ? void 0 : currentProgram.picture; });
/**
 * @returns the current program pictureBig
 */
export var currentProgramPictureBigSelector = createSelector(currentProgramSelector, function (currentProgram) { return currentProgram === null || currentProgram === void 0 ? void 0 : currentProgram.pictureBig; });
/**
 * @returns the current program pictureSquare
 */
export var currentProgramPictureSquareSelector = createSelector(currentProgramSelector, function (currentProgram) { return currentProgram === null || currentProgram === void 0 ? void 0 : currentProgram.pictureSquare; });
/**
 * @returns the current program pictureHd
 */
export var currentProgramPictureHdSelector = createSelector(currentProgramSelector, function (currentProgram) { return currentProgram === null || currentProgram === void 0 ? void 0 : currentProgram.pictureHd; });
/**
 * @returns the current program start time
 */
export var currentProgramStartTimeSelector = createSelector(currentProgramSelector, function (currentProgram) { return currentProgram === null || currentProgram === void 0 ? void 0 : currentProgram.startTime; });
/**
 * @returns the current program end time
 */
export var currentProgramEndTimeSelector = createSelector(currentProgramSelector, function (currentProgram) { return currentProgram === null || currentProgram === void 0 ? void 0 : currentProgram.endTime; });
/**
 * @returns whether or not the current program has started
 */
export var isCurrentProgramStartedSelector = createSelector(currentProgramSelector, function (currentProgram) { return currentProgram !== null; });
/**
 * @returns array of all programs
 */
export var allProgramsSelector = createSelector(programSelector, function (program) { return program.allPrograms || []; });
/**
 * @returns array of all current programs
 */
export var allCurrentProgramsSelector = createSelector(programSelector, function (program) { return program.allCurrentPrograms || []; });
/**
 * @returns array of previous programs
 */
export var previousProgramsSelector = createSelector(programSelector, function (program) { return program.previousPrograms || []; });
/**
 * @returns array of next programs
 */
export var nextProgramsSelector = createSelector(programSelector, function (program) { return program.nextPrograms || []; });
/**
 * @returns array of timeline menu
 */
export var timelineMenuSelector = createSelector(programSelector, function (program) { return program.timelineMenu || []; });
/**
 * @returns whether or not the timeline menu programs is available
 */
export var isTimelineMenuPreviousProgramsAvailableSelector = createSelector(timelineMenuSelector, function (timelineMenu) { return timelineMenu.length > 1; });
/**
 * @returns the timeline mode
 */
export var timelineModeSelector = createSelector(programSelector, function (program) { return program.timelineMode; });
/**
 * @returns the current program title
 */
export var currentTitleSelector = createSelector(currentProgramSelector, function (currentProgram) { return currentProgram === null || currentProgram === void 0 ? void 0 : currentProgram.title; });
/**
 * @returns whether the details are loading or not (undefined means we are fetching it.)
 */
export var isDetailsLoadingSelector = createSelector(dataSelector, function (data) { return data === undefined; });
/**
 * @returns whether the details are available or not (null means that we did fetch but we failed at it)
 */
export var isDetailsNotAvailableSelector = createSelector(dataSelector, function (data) { return data === null; });
/**
 * @returns whether or not the program is ready
 */
export var isProgramReadySelector = createSelector(isLiveSelector, isCurrentProgramStartedSelector, function (isLive, isCurrentProgramStarted) {
    return isLive ? isCurrentProgramStarted : true;
});
/**
 * @returns the current program id
 */
export var currentProgramIdSelector = createSelector(currentProgramSelector, function (currentProgram) { return currentProgram === null || currentProgram === void 0 ? void 0 : currentProgram.id; });
/**
 * @returns the current program broadcastId
 */
export var currentProgramBroadcastIdSelector = createSelector(currentProgramSelector, function (currentProgram) { return currentProgram === null || currentProgram === void 0 ? void 0 : currentProgram.broadcastId; });
/**
 * @returns the zaplist menu
 */
export var zaplistMenuSelector = createSelector(programSelector, function (program) { return program.zaplistMenu; });
/**
 * @returns the zaplist mode
 */
export var zaplistModeSelector = createSelector(programSelector, function (program) { return program.zaplistMode; });
/**
 * @returns the zaplist search query
 */
export var zaplistSearchQuerySelector = createSelector(programSelector, function (program) { return program.zaplistSearchQuery; });
/**
 * @returns array of genres of the program
 */
export var programGenresSelector = createSelector(programSelector, function (program) { return program.programGenres; });
/**
 * @returns whether or not anti ad skip sequence is active
 */
export var isAntiAdSkipSequenceActiveSelector = createSelector(programSelector, function (program) { return program.isAntiAdSkipSequenceActive; });
/**
 * @returns whether or not we are fetching globalChannels
 */
export var isFetchingAllChannelsProgramsSelector = createSelector(programSelector, function (program) { return program.isFetchingAllChannelsPrograms; });
/**
 * @returns array of differents time codes of the current program
 */
export var currentProgramTimeCodesSelector = createSelector(currentProgramSelector, function (currentProgram) { return currentProgram === null || currentProgram === void 0 ? void 0 : currentProgram.timeCodes; });
/**
 * @returns the current program current timecode
 */
export var currentProgramCurrentTimeCodeSelector = createSelector(currentProgramTimeCodesSelector, currentTimeSelector, function (currentProgramTimeCodes, currentTime) {
    return currentProgramTimeCodes === null || currentProgramTimeCodes === void 0 ? void 0 : currentProgramTimeCodes.find(function (timeCode) {
        return timeCode.startTime < currentTime && timeCode.endTime > currentTime;
    });
});
/**
 * @returns whether current program first time code is an ad break (if the type of the sequence is 6)
 */
export var isCurrentProgramStartingWithAdBreakSelector = createSelector(currentProgramTimeCodesSelector, function (currentProgramTimeCodes) { var _a; return ((_a = currentProgramTimeCodes === null || currentProgramTimeCodes === void 0 ? void 0 : currentProgramTimeCodes[0]) === null || _a === void 0 ? void 0 : _a.type) === timeCodeTypes.ads; });
/**
 * @returns whether current program time code is the first time code of the program
 */
export var isCurrentTimeCodeProgramFirstTimeCodeSelector = createSelector(currentProgramTimeCodesSelector, currentProgramCurrentTimeCodeSelector, function (currentProgramTimeCodes, currentProgramCurrentTimeCode) {
    return (currentProgramCurrentTimeCode === null || currentProgramCurrentTimeCode === void 0 ? void 0 : currentProgramCurrentTimeCode.startTime.getTime()) ===
        (currentProgramTimeCodes === null || currentProgramTimeCodes === void 0 ? void 0 : currentProgramTimeCodes[0].startTime.getTime());
});
/**
 * @returns time left between current time and the end of a type 6 (ads) sequence in seconds
 */
export var timeRemainingInAdSequenceSelector = createSelector(currentProgramCurrentTimeCodeSelector, currentTimeSelector, function (currentProgramTimeCodesCurrentTimeCode, currentTime) {
    return currentProgramTimeCodesCurrentTimeCode &&
        currentProgramTimeCodesCurrentTimeCode.type === timeCodeTypes.ads
        ? Math.max(Math.floor((currentProgramTimeCodesCurrentTimeCode.endTime.getTime() -
            toDate(currentTime).getTime()) /
            1000), 0)
        : 0;
});
/**
 * @returns the number or seconds left before being able to skip live ads
 * when ad was "antiAdSkip" when launching a startover or in the middle of the content
 */
export var noSeekTimeRemainingForAdSelector = createSelector(isCurrentTimeCodeProgramFirstTimeCodeSelector, currentProgramCurrentTimeCodeSelector, noSeekDurationForAdAtBeginningSelector, noSeekDurationForAdAfterBeginningSelector, currentTimeSelector, function (isCurrentTimeCodeProgramFirstTimeCode, currentProgramCurrentTimeCode, noSeekDurationForAdAtBeginning, noSeekDurationForAdAfterBeginning, currentTime) {
    var noSeekDurationForAd = isCurrentTimeCodeProgramFirstTimeCode
        ? noSeekDurationForAdAtBeginning
        : noSeekDurationForAdAfterBeginning;
    var formattedCurrentProgramCurrentTimeCodeStartTime = currentProgramCurrentTimeCode
        ? milliSecondsToSeconds(currentProgramCurrentTimeCode.startTime.getTime())
        : null;
    var formattedCurrentTime = milliSecondsToSeconds(toDate(currentTime).getTime());
    var durationOfAdAlreadySeen = formattedCurrentProgramCurrentTimeCodeStartTime &&
        formattedCurrentTime >= formattedCurrentProgramCurrentTimeCodeStartTime
        ? formattedCurrentTime - formattedCurrentProgramCurrentTimeCodeStartTime
        : null;
    return currentProgramCurrentTimeCode &&
        currentProgramCurrentTimeCode.type === timeCodeTypes.ads &&
        noSeekDurationForAd &&
        durationOfAdAlreadySeen
        ? Math.max(Math.floor(noSeekDurationForAd - durationOfAdAlreadySeen), 0)
        : 0;
});
/**
 * @returns whether the noSeekDurationForAd (AtBeginning or AfterBeginig) given by the PDS (probably 120s)
 * is longer than the ad sequence
 */
export var isNoSeekDurationForAdTooLongSelector = createSelector(isCurrentTimeCodeProgramFirstTimeCodeSelector, noSeekDurationForAdAtBeginningSelector, noSeekDurationForAdAfterBeginningSelector, currentProgramCurrentTimeCodeSelector, function (isCurrentTimeCodeProgramFirstTimeCode, noSeekDurationForAdAtBeginning, noSeekDurationForAdAfterBeginning, currentProgramCurrentTimeCode) {
    var noSeekDurationForAd = isCurrentTimeCodeProgramFirstTimeCode
        ? noSeekDurationForAdAtBeginning
        : noSeekDurationForAdAfterBeginning;
    return ((currentProgramCurrentTimeCode &&
        noSeekDurationForAd &&
        noSeekDurationForAd >
            (currentProgramCurrentTimeCode.endTime.getTime() -
                currentProgramCurrentTimeCode.startTime.getTime()) /
                1000) ||
        false);
});
/**
 * @returns whether or not there was an error on the current program
 */
export var hadErrorOnCurrentProgramSelector = createSelector(programSelector, function (program) { return program.hadErrorOnCurrentProgram; });
// Select the countdown value to display inside the skip button (if the ad sequence
// is shorter than the time authorized to skip, we display the remaining time of the ad sequence)
export var antiAdSkipCountdownValueSelector = createSelector(isNoSeekDurationForAdTooLongSelector, timeRemainingInAdSequenceSelector, noSeekTimeRemainingForAdSelector, function (isNoSeekDurationForAdTooLong, timeRemainingInAdSequence, noSeekTimeRemainingForAd) {
    var antiAdSkipCountdownValue = isNoSeekDurationForAdTooLong
        ? timeRemainingInAdSequence
        : noSeekTimeRemainingForAd;
    return antiAdSkipCountdownValue || 0;
});
export var isProgramEndTimeBeforeLiveTimeSelector = createSelector(currentProgramCurrentTimeCodeSelector, liveTimeSelector, function (currentProgramCurrentTimeCode, liveTime) {
    if (!currentProgramCurrentTimeCode || !liveTime) {
        return false;
    }
    return currentProgramCurrentTimeCode.endTime.getTime() < liveTime.getTime();
});
