import { createSelector } from 'reselect';
import { createDeepEqualSelector } from '../../utils/reselect';
import { castMetadataSelector, isCastActiveSelector, isCastPendingSelector, } from './castSelector';
export var dataSelector = function (state) { return state.data; };
/**
 * @returns whether the player is in live mode
 */
export var isLiveSelector = createSelector(dataSelector, function (data) { return data.isLive; });
/**
 * @returns whether the player is premium
 */
export var isPremiumSelector = createSelector(dataSelector, function (data) {
    return 'isPremium' in data ? data.isPremium : undefined;
});
/**
 * @returns the epgId of the content
 */
export var epgIdSelector = createSelector(dataSelector, function (data) {
    return 'epgId' in data ? data.epgId : undefined;
});
/**
 * @returns the duration of the content
 */
export var durationSelector = createSelector(dataSelector, function (data) {
    return 'duration' in data ? data.duration : undefined;
});
/**
 * @returns the id
 */
export var idSelector = createSelector(dataSelector, isCastActiveSelector, isCastPendingSelector, castMetadataSelector, function (data, isCastActive, isCastPending, castMetadata) {
    var _a;
    if (isCastActive && !isCastPending) {
        return (_a = castMetadata.customData) === null || _a === void 0 ? void 0 : _a.id;
    }
    return 'id' in data ? data.id : undefined;
});
/**
 * @returns the title of the content
 */
export var titleSelector = createSelector(dataSelector, isCastActiveSelector, castMetadataSelector, function (data, isCastActive, castMetadata) {
    if (isCastActive) {
        return castMetadata.title;
    }
    return 'title' in data ? data.title : undefined;
});
/**
 * @returns the subtitle of the content
 */
export var subtitleSelector = createSelector(dataSelector, isCastActiveSelector, castMetadataSelector, function (data, isCastActive, castMetadata) {
    if (isCastActive) {
        return castMetadata.subtitle;
    }
    return 'subtitle' in data ? data.subtitle : undefined;
});
/**
 * @returns the list of images of the content
 */
export var imagesSelector = createSelector(dataSelector, function (data) {
    return 'images' in data ? data.images : undefined;
});
/**
 * @returns the poster representing the content
 */
export var posterSelector = createSelector(dataSelector, isCastActiveSelector, castMetadataSelector, function (data, isCastActive, castMetadata) {
    var _a;
    if (isCastActive) {
        return (_a = castMetadata.images[0]) === null || _a === void 0 ? void 0 : _a.url;
    }
    return 'poster' in data ? data.poster : undefined;
});
/**
 * @returns the picture square representing the content
 */
export var pictureSquareSelector = createSelector(dataSelector, function (data) {
    return 'pictureSquare' in data ? data.pictureSquare : undefined;
});
/**
 * @returns the content id
 */
export var contentIdSelector = createSelector(isLiveSelector, dataSelector, function (isLive, data) {
    if (isLive && 'epgId' in data) {
        return data.epgId;
    }
    if (!isLive && 'id' in data) {
        return data.id;
    }
    return undefined;
});
/**
 * @returns the live type
 */
export var liveTypeSelector = createSelector(dataSelector, function (data) {
    return 'liveType' in data ? data.liveType : undefined;
});
/**
 * @returns the channel logo URL of the VOD content
 */
export var vodChannelLogoUrlSelector = createSelector(dataSelector, isCastActiveSelector, castMetadataSelector, function (data, isCastActive, castMetadata) {
    var _a;
    if (isCastActive) {
        return (_a = castMetadata.customData) === null || _a === void 0 ? void 0 : _a.channelLogoUrl;
    }
    return 'channelLogoUrl' in data ? data.channelLogoUrl : undefined;
});
/**
 * @returns the channel name
 */
export var channelNameSelector = createSelector(isLiveSelector, dataSelector, isCastActiveSelector, castMetadataSelector, function (isLive, data, isCastActive, castMetadata) {
    var _a, _b, _c;
    if (isCastActive) {
        return ((isLive
            ? (_b = (_a = castMetadata.customData) === null || _a === void 0 ? void 0 : _a.channel) === null || _b === void 0 ? void 0 : _b.name
            : (_c = castMetadata.customData) === null || _c === void 0 ? void 0 : _c.channelName) || '');
    }
    if (isLive && 'name' in data) {
        return data.name;
    }
    if (!isLive && 'channelName' in data) {
        return data.channelName;
    }
    return undefined;
});
/**
 * @returns the item type
 */
export var itemTypeSelector = createSelector(dataSelector, function (data) {
    return 'itemType' in data ? data.itemType : undefined;
});
/**
 * @returns the id view
 */
export var idViewSelector = createSelector(dataSelector, function (data) {
    return 'idView' in data ? data.idView : undefined;
});
/**
 * @returns the locators for skip
 */
export var getLocatorsForSkip = createSelector(dataSelector, function (data) {
    return 'locators' in data ? data.locators : undefined;
});
/**
 * @returns whether enableAd is true or false
 */
export var enableAdSelector = createSelector(dataSelector, function (data) {
    return 'enableAd' in data ? data.enableAd : undefined;
});
/**
 * @returns the category of ad
 */
export var adCategorySelector = createSelector(dataSelector, function (data) {
    return 'adCategory' in data ? data.adCategory : undefined;
});
/**
 * @returns the content type
 */
export var contentTypeSelector = createSelector(dataSelector, function (data) {
    return 'contentType' in data ? data.contentType : undefined;
});
/**
 * @returns the com mode
 */
export var comModeSelector = createSelector(dataSelector, function (data) {
    return 'comMode' in data ? data.comMode : undefined;
});
/**
 * @returns the version
 */
export var versionSelector = createSelector(dataSelector, function (data) {
    return 'version' in data ? data.version : undefined;
});
/**
 * @returns the quality
 */
export var qualitySelector = createSelector(dataSelector, function (data) {
    return 'quality' in data ? data.quality : undefined;
});
/**
 * @returns the maturity rating
 */
export var maturityRatingSelector = createSelector(dataSelector, function (data) {
    return 'maturityRating' in data ? data.maturityRating : undefined;
});
/**
 * @returns the advisories maturity rating
 */
export var advisoriesMaturityRatingSelector = createSelector(maturityRatingSelector, function (maturityRating) { return maturityRating === null || maturityRating === void 0 ? void 0 : maturityRating.advisories; });
/**
 * @returns the parental rating value
 */
export var parentalRatingValueSelector = createSelector(dataSelector, isLiveSelector, isCastActiveSelector, castMetadataSelector, maturityRatingSelector, function (data, isLive, isCastActive, castMetadata, maturityRating) {
    if (isCastActive) {
        return castMetadata.contentRating;
    }
    if (isLive) {
        return 'parentalRating' in data ? data.parentalRating : undefined;
    }
    return maturityRating === null || maturityRating === void 0 ? void 0 : maturityRating.parentalRating;
});
/**
 * @returns the audio descriptive
 */
export var audioDescriptiveSelector = createSelector(dataSelector, function (data) {
    return 'audioDescriptive' in data ? data.audioDescriptive : undefined;
});
/**
 * @returns whether subtitles hearing impared is wanted
 */
export var subtitlesHearingImparedSelector = createSelector(dataSelector, function (data) {
    return 'subtitlesHearingImpared' in data
        ? data.subtitlesHearingImpared
        : undefined;
});
/**
 * @returns the synopsis
 */
export var synopsisSelector = createSelector(dataSelector, function (data) {
    return 'synopsis' in data ? data.synopsis : undefined;
});
/**
 * @returns the deep equal BIF files
 */
export var deepEqualBifFilesSelector = createDeepEqualSelector(dataSelector, isCastActiveSelector, castMetadataSelector, function (data, isCastActive, castMetadata) {
    if (isCastActive) {
        return castMetadata.customData.bifFiles;
    }
    if ('bifFiles' in data) {
        return data.bifFiles;
    }
    return undefined;
});
/**
 * @returns the stream event overlays
 */
export var streamEventOverlaysSelector = createSelector(dataSelector, function (data) { return data.streamEventOverlays; });
/**
 * @returns the next episode id
 */
export var nextEpisodeIdSelector = createSelector(dataSelector, isCastActiveSelector, castMetadataSelector, function (data, isCastActive, castMetadata) {
    var _a;
    if (isCastActive) {
        return castMetadata.customData.nextEpisodeId;
    }
    return 'series' in data ? (_a = data.series) === null || _a === void 0 ? void 0 : _a.nextEpisodeId : undefined;
});
/**
 * @returns the idKey
 */
export var idKeySelector = createSelector(dataSelector, function (data) {
    return 'idKey' in data ? data.idKey : undefined;
});
