import { logger } from '../../logger';

interface IStoreAPI {
  getItem: (name: string) => Promise<string | null>;
  setItem: (name: string, value: string) => Promise<void>;
  removeItem: (name: string) => Promise<void>;
  getAllKeys: () => Promise<string[]>;
}

/**
 * A function that checks if the local storage is available on the browser by trying
 * to set & remove a test value. The function throws if something bad happens
 * and returns false
 * @returns true or false.
 */
function isLocalStorageAvailable(): boolean {
  try {
    const storage = window.localStorage;
    const storageTest = '__storage_test__';
    storage.setItem(storageTest, storageTest);
    storage.getItem(storageTest);
    storage.removeItem(storageTest);
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * A utility class to manipulate localStorage properties inside the OnePlayer
 */
class LocalStorage {
  NAMESPACE_GLOBAL = 'oneplayer';

  namespace: string;

  storageAPI: IStoreAPI;

  tmpStorage: { [key: string]: any };

  constructor(storeName?: string) {
    this.namespace = storeName
      ? `${this.NAMESPACE_GLOBAL}:${storeName}`
      : this.NAMESPACE_GLOBAL;
    this.tmpStorage = {};

    // Check if localStorage exist and if we can access it
    // Sometime it's there but access is denied)

    if (isLocalStorageAvailable()) {
      this.storageAPI = {
        getItem: async (name: string): Promise<string | null> =>
          window.localStorage.getItem(name),
        setItem: async (name: string, value: string): Promise<void> => {
          window.localStorage.setItem(name, value);
        },
        removeItem: async (name: string): Promise<void> => {
          window.localStorage.removeItem(name);
        },
        getAllKeys: async (): Promise<string[]> =>
          Object.keys(window.localStorage),
      };
    } else {
      this.storageAPI = {
        getItem: async (name): Promise<any> => this.tmpStorage[name],
        setItem: async (name, value): Promise<void> => {
          this.tmpStorage[name] = value;
        },
        removeItem: async (name): Promise<void> => {
          delete this.tmpStorage[name];
        },
        getAllKeys: async (): Promise<any[]> => Object.keys(this.tmpStorage),
      };
    }
  }

  async getItem(name: string): Promise<any | null> {
    const keyName = `${this.namespace}:${name}`;
    const item = await this.storageAPI.getItem(keyName);
    if (item === null) {
      return null;
    }
    if (item === undefined) {
      return undefined;
    }
    return JSON.parse(item);
  }

  async setItem(name: string, value: any): Promise<void> {
    try {
      await this.storageAPI.setItem(
        `${this.namespace}:${name}`,
        JSON.stringify(value),
      );
    } catch (e) {
      logger.warn(
        `Unable to set ${this.namespace}:${name} value in localStorage`,
        e,
      );
    }
  }

  async removeItem(name: string): Promise<void> {
    try {
      await this.storageAPI.removeItem(`${this.namespace}:${name}`);
    } catch (e) {
      logger.warn(
        `Unable to remove ${this.namespace}:${name} value in localStorage`,
        e,
      );
    }
  }

  async getAllKeys(): Promise<string[]> {
    const allKeys = await this.storageAPI.getAllKeys();
    const mappedKeys = allKeys.reduce<string[]>((keyList, key) => {
      if (key.startsWith(this.namespace)) {
        const sanitizedKey = key.replace(`${this.namespace}:`, '');
        keyList.push(sanitizedKey);
        return keyList;
      }
      keyList.push(key);
      return keyList;
    }, []);
    return mappedKeys;
  }
}

export const reduxPersistStorage = new LocalStorage('redux-persist');
export const logStorage = new LocalStorage('log');

export default LocalStorage;
