import { createSelector } from 'reselect';
import { calculateVideoHeight, getMobilePortraitSidebarTop, getMobilePortraitSidePanelHeight, getMobilePortraitVideoBottom, getMobilePortraitVideoTop, getVideoHeight, isBottomOverlayElementRaised, isMobilePortraitLayout as isMobilePortraitLayoutUtil, } from '../utils/layout';
import { shouldShowToggleControlsSelector } from './controlsSelector';
import { heightSelector, widthSelector } from './frameSelector';
var controlBarHeightMobilePortrait = require('../assets/styles/sizes.css').controlBarHeightMobilePortrait;
/**
 *  * @returns {function(object): number} (pixels)
 */
export var mobilePortraitVideoHeightSelector = createSelector(widthSelector, function (frameWidth) { return calculateVideoHeight(frameWidth); });
/**
 *  * @returns {function(object): boolean}
 */
export var isMobilePortraitLayoutSelector = createSelector(mobilePortraitVideoHeightSelector, widthSelector, heightSelector, function (videoHeight, frameWidth, frameHeight) {
    return isMobilePortraitLayoutUtil(videoHeight, frameWidth, frameHeight);
});
/**
 *  * @returns {function(object): number} (pixels)
 */
export var mobilePortraitVideoTopSelector = createSelector(heightSelector, mobilePortraitVideoHeightSelector, function (frameHeight, videoHeight) {
    return getMobilePortraitVideoTop(frameHeight, videoHeight);
});
/**
 *  * @returns {function(object): number} (pixels)
 */
export var mobilePortraitVideoBottomSelector = createSelector(heightSelector, mobilePortraitVideoHeightSelector, function (frameHeight, videoHeight) {
    return getMobilePortraitVideoBottom(frameHeight, videoHeight);
});
/**
 *  * @returns {function(object): number} (pixels)
 */
export var mobilePortraitSidePanelHeightSelector = createSelector(heightSelector, mobilePortraitVideoHeightSelector, function (frameHeight, videoHeight) {
    return getMobilePortraitSidePanelHeight(frameHeight, videoHeight);
});
/**
 *  * @returns {function(object): number} (pixels)
 */
export var mobilePortraitSidebarTopSelector = createSelector(mobilePortraitVideoBottomSelector, function (videoBottom) {
    return getMobilePortraitSidebarTop(videoBottom, parseInt(controlBarHeightMobilePortrait, 10));
});
/**
 *  * @returns {function(object): number} (pixels)
 */
export var videoHeightSelector = createSelector(isMobilePortraitLayoutSelector, heightSelector, mobilePortraitVideoHeightSelector, function (isMobilePortraitLayout, frameHeight, mobilePortraitVideoHeight) {
    return getVideoHeight(isMobilePortraitLayout, frameHeight, mobilePortraitVideoHeight);
});
/**
 *  * @returns {function(object): boolean}
 */
export var isBottomOverlayElementRaisedSelector = createSelector(isMobilePortraitLayoutSelector, shouldShowToggleControlsSelector, function (isMobilePortraitLayout, isControlbarVisible) {
    return isBottomOverlayElementRaised(isMobilePortraitLayout, isControlbarVisible);
});
