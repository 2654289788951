import { TProgramParsed } from '@canalplus/oneplayer-types';

/**
 *
 * @param program potentially live program
 * @param liveDate live date to be compared with
 * @returns wether the program is live
 */
export default function isLiveProgramChannel(
  program: TProgramParsed,
  liveDate: Date,
): boolean {
  return program.startTime <= liveDate && program.endTime >= liveDate;
}
