import { ICompatibleKeySystem } from 'rx-player/experimental/tools/mediaCapabilitiesProber';

import { BusinessTypes, DrmTypes } from '@canalplus/oneplayer-constants';
import {
  ICapabilities,
  ISupportedDRM,
  TDrmType,
  TKeySystem,
  TPlatform,
  TSecurityLevels,
  TWidevineSecurityLevels,
} from '@canalplus/oneplayer-types';

const {
  KEY_SYSTEMS: {
    NAGRA,
    WIDEVINE,
    PLAYREADY,
    PLAYREADY_SOFTWARE,
    PLAYREADY_HARDWARE,
    PLAYREADY_RECOMMENDATION,
    PLAYREADY_RECOMMENDATION_2000,
    PLAYREADY_RECOMMENDATION_3000,
    PLAYREADY_RECOMMENDATION_ESVM,
    FAIRPLAY,
  },
  PLAYREADY_RECOMMENDATION_KEY_SYSTEMS,
  DRM_TYPES,
} = DrmTypes;
const {
  DEVICE_TYPES: {
    G9_TECHNICOLOR,
    G9L_TECHNICOLOR,
    G9MINI_HUMAX,
    G9MINIVE_HUMAX,
    G10_HUMAX,
    G10_SAGEMCOM,
    G10CPI_SAGEMCOM,
    G11,
    HISENSE,
    LG,
    PLAYSTATION_4,
    PHILIPS,
    SAMSUNG,
    DESKTOP_MOBILE,
    FIRETVREACT,
    SFR_STB7_IPTV,
    SFR_STB8_IPTV,
    SFR_STB8_CABLE,
  },
  SECURITY_LEVELS: { L1, L2, L3, SL2000, SL3000 },
  MKPL,
  SKPL,
} = BusinessTypes;

// Robustnesses used by widevine
const WIDEVINE_ROBUSTNESSES = [
  'HW_SECURE_ALL',
  'HW_SECURE_DECODE',
  'HW_SECURE_CRYPTO',
  'SW_SECURE_DECODE',
  'SW_SECURE_CRYPTO',
];

const singleLicensePerInitData = 'init-data';
const singleLicensePerPeriods = 'periods';

// Robustnesses used by playready
const PLAYREADY_ROBUSTNESSES = ['3000', '2000'];

/**
 * Some devices lie or are not be able to tell us what DRM config they support (this is the case with PS4).
 * So, we have to create keySytem configuration especially for those devices.
 * For example with the PS4, when we ask for DRM availabilities, it tells us that PLAYREADY_HARDWARE is supported,
 * but when it comes to push the license into the CDM system or even create the challenge, it breaks :(
 * So, to bypass that lowlevel bug (from the manufacturer), we only test the ones we know that are really available.
 */
const CENC_KEY_SYSTEMS_PRIORITY_SPECIFIC: Partial<
  Record<BusinessTypes.DEVICE_TYPES, TKeySystem[]>
> = {
  [SAMSUNG]: [PLAYREADY_HARDWARE, PLAYREADY_SOFTWARE, PLAYREADY],
  [PHILIPS]: [PLAYREADY_HARDWARE, PLAYREADY_SOFTWARE, PLAYREADY],
  [LG]: [PLAYREADY_HARDWARE, PLAYREADY_SOFTWARE, PLAYREADY],
  [FIRETVREACT]: [WIDEVINE],
  [HISENSE]: [PLAYREADY_HARDWARE, PLAYREADY_SOFTWARE, PLAYREADY],
  [PLAYSTATION_4]: [PLAYREADY],
  [G9_TECHNICOLOR]: [NAGRA],
  [G9L_TECHNICOLOR]: [NAGRA],
  [G9MINI_HUMAX]: [NAGRA],
  [G9MINIVE_HUMAX]: [NAGRA],
  [G10_HUMAX]: [NAGRA],
  [G10_SAGEMCOM]: [NAGRA],
  [G10CPI_SAGEMCOM]: [NAGRA],
  [G11]: [NAGRA],
  [SFR_STB7_IPTV]: [PLAYREADY],
  [SFR_STB8_IPTV]: [PLAYREADY],
  [SFR_STB8_CABLE]: [PLAYREADY],
};

const CENC_KEY_SYSTEMS_PRIORITY_DEFAULT: TKeySystem[] = [
  FAIRPLAY,
  PLAYREADY_RECOMMENDATION_ESVM,
  PLAYREADY_RECOMMENDATION_3000,
  WIDEVINE,
  PLAYREADY_RECOMMENDATION,
  PLAYREADY_RECOMMENDATION_2000,
  PLAYREADY_HARDWARE,
  PLAYREADY_SOFTWARE,
  PLAYREADY,
];

/**
 * @param platform type of content we play
 * @returns whether Drm is needed or not
 */
export function isDrmNeeded(platform: string): boolean {
  return !['directfile'].includes(platform);
}

/**
 *
 * @param drmEnv option allowing to communicate your decryption-related preferences
 * @returns if the current CDM need a server certificate.
 */
export function isDrmNeedsCertificate(drmEnv: string): boolean {
  return drmEnv === DRM_TYPES.FAIRPLAY || drmEnv === DRM_TYPES.WIDEVINE;
}

/**
 * @param mimeType appropriate mime-type for the media
 * @returns convert mimeType to Drm Name
 */
export function keySystemToDrmName(mimeType: TKeySystem): TDrmType {
  const DRM_MIME_TYPES_MAP: Record<TKeySystem, TDrmType> = {
    [NAGRA]: DRM_TYPES.NAGRA,
    [WIDEVINE]: DRM_TYPES.WIDEVINE,
    [PLAYREADY_SOFTWARE]: DRM_TYPES.PLAYREADY,
    [PLAYREADY_HARDWARE]: DRM_TYPES.PLAYREADY,
    [PLAYREADY_RECOMMENDATION_ESVM]: DRM_TYPES.PLAYREADY,
    [PLAYREADY_RECOMMENDATION_3000]: DRM_TYPES.PLAYREADY,
    [PLAYREADY_RECOMMENDATION]: DRM_TYPES.PLAYREADY,
    [PLAYREADY_RECOMMENDATION_2000]: DRM_TYPES.PLAYREADY,
    [PLAYREADY]: DRM_TYPES.PLAYREADY,
    [FAIRPLAY]: DRM_TYPES.FAIRPLAY,
  };
  return DRM_MIME_TYPES_MAP[mimeType];
}

/**
 * We only need robustnesses on widevine CDM
 * @param keySystem option allowing to communicate your decryption-related preferences
 * @returns robustnesses
 */
export function getDRMRobustnesses(
  keySystem: TKeySystem,
): string[] | undefined[] {
  const keySystemName = keySystemToDrmName(keySystem);
  if (keySystemName === DRM_TYPES.WIDEVINE) {
    return WIDEVINE_ROBUSTNESSES;
  }
  if (keySystem === PLAYREADY_RECOMMENDATION) {
    return PLAYREADY_ROBUSTNESSES;
  }
  return [undefined];
}

type TKeyPerLicense = typeof MKPL | typeof SKPL;

/**
 *
 * @param keyPerLicense type of key per License
 * @param drmEnv name of DRM environement
 * @returns get single License per periods or per init data
 */
export function getSingleLicensePer(
  keyPerLicense: TKeyPerLicense = SKPL,
  drmEnv: TDrmType,
): typeof singleLicensePerPeriods | typeof singleLicensePerInitData {
  if (drmEnv !== DRM_TYPES.FAIRPLAY && keyPerLicense === MKPL) {
    return singleLicensePerPeriods;
  }
  return singleLicensePerInitData;
}

/**
 * Get Widevine security level from robustnesses (L1,L2,L3)
 * @param robustnesses robustness values
 * @returns L1|L2|L3
 */
export function getWidevineSecurityLevel(
  robustnesses: string[],
): TWidevineSecurityLevels {
  let hasHardwareDecode = false;
  let hasHardwareDecrypt = false;
  for (let i = 0, len = robustnesses.length; i < len; i += 1) {
    const robustness = robustnesses[i];
    if (robustness === 'HW_SECURE_ALL') {
      hasHardwareDecode = true;
      hasHardwareDecrypt = true;
      break;
    } else if (robustness === 'HW_SECURE_DECODE') {
      hasHardwareDecode = true;
    } else if (robustness === 'HW_SECURE_CRYPTO') {
      hasHardwareDecrypt = true;
    }
  }

  if (hasHardwareDecrypt) {
    return hasHardwareDecode ? L1 : L2;
  }
  return L3;
}

/**
 * @param keySystemConfiguration keySystem config
 * @returns valid array of robustness
 */
export function getRobustnessesFromKeySystemConfiguration(
  keySystemConfiguration: MediaKeySystemConfiguration,
): string[] {
  if (keySystemConfiguration === undefined) {
    return [];
  }

  const { videoCapabilities } = keySystemConfiguration;
  if (videoCapabilities === undefined) {
    return [];
  }
  const hashMap: { [prop: string]: boolean } = {};
  const capabilities: string[] = [];
  for (let i = 0, len = videoCapabilities.length; i < len; i += 1) {
    const capability = videoCapabilities[i];
    if (
      typeof capability.robustness === 'string' &&
      capability.robustness !== '' &&
      hashMap[capability.robustness] !== true
    ) {
      hashMap[capability.robustness] = true;
      capabilities.push(capability.robustness);
    }
  }
  return capabilities;
}

/**
 * @param keySystem option allowing to communicate your decryption-related preferences
 * @param shouldPersistLicense config param whether or not we should persist license
 * @param noCapabilities When set to `true`, do not set the `audioCapabilities` and
 * `videoCapabilities` in the returned `MediaKeySystemConfiguration`. Those very
 * rarely happen to be buggy browser-side.
 * @returns key System Configurations
 */
export function getKeySystemConfigurations(
  keySystem: TKeySystem,
  shouldPersistLicense?: boolean,
  noCapabilities?: boolean,
): MediaKeySystemConfiguration[] {
  const videoCapabilities: ICapabilities[] = [];
  const audioCapabilities: ICapabilities[] = [];

  const robustnesses = getDRMRobustnesses(keySystem);
  robustnesses.forEach((robustness: string | undefined) => {
    videoCapabilities.push({
      contentType: 'video/mp4;codecs="avc1.4d401e"', // standard mp4 codec
      robustness,
    });
    videoCapabilities.push({
      contentType: 'video/mp4;codecs="avc1.42e01e"',
      robustness,
    });
    videoCapabilities.push({
      contentType: 'video/webm;codecs="vp8"',
      robustness,
    });
    audioCapabilities.push({
      contentType: 'audio/mp4;codecs="mp4a.40.2"', // standard mp4 codec
      robustness,
    });
  });

  // 'temporary licence' configuration
  const keySystemConfiguration: MediaKeySystemConfiguration = {
    initDataTypes: ['cenc'],
    videoCapabilities,
    audioCapabilities,
    label: keySystem,
  };

  const keySystemConfigurations = [keySystemConfiguration];

  // if persistent licence are requested, we return 2 configurations: one with and
  // one without the options necessary for the peristent license keysystem configuration
  if (
    shouldPersistLicense &&
    [NAGRA, WIDEVINE, ...PLAYREADY_RECOMMENDATION_KEY_SYSTEMS].includes(
      keySystem,
    )
  ) {
    keySystemConfigurations.unshift({
      ...keySystemConfiguration,

      // Both properties here, are mandatory in order to detect a CDM.
      // on widevine keysystem, adding the property 'distinctiveIdentifier' to the keysystem configuration
      // makes the configuration unsupported.
      ...(keySystem !== WIDEVINE && {
        distinctiveIdentifier: 'required',
      }),
      sessionTypes: ['temporary', 'persistent-license'],
    });
  }

  if (noCapabilities) {
    keySystemConfigurations.push({
      ...keySystemConfiguration,
      videoCapabilities: [],
      audioCapabilities: [],
    });
  }

  return keySystemConfigurations;
}

/**
 * Helps to filter the list of drm configurations after seeing which one
 * is supported by the browser. The OnePlayer only wants to keep one drm config by keystem if possible.
 * @param cencDrmConfigurations list of drm configurations
 * @returns same list of drm configuration removing
 * the un-persisted alternatives of a persisted keysystem configuration.
 */
export function filterUnwantedCencDrmConfigurations(
  cencDrmConfigurations: ISupportedDRM[],
): ISupportedDRM[] {
  // regroup config by keysystem name 'widevine'...
  const drmConfigsGroupedByKeySystem: Partial<
    Record<TKeySystem, ISupportedDRM[]>
  > = {};
  cencDrmConfigurations.forEach((drm: ISupportedDRM) => {
    if (drmConfigsGroupedByKeySystem[drm.keySystem]) {
      drmConfigsGroupedByKeySystem[drm.keySystem]?.push(drm);
    } else {
      drmConfigsGroupedByKeySystem[drm.keySystem] = [drm];
    }
  });
  // iterate over each group to return either the persisted config or the other configs
  return Object.values(drmConfigsGroupedByKeySystem).reduce(
    (acc: ISupportedDRM[], drmGroup) => {
      const drmPersistedConfig = drmGroup.find((drm) => drm.canPersistLicense);
      if (drmPersistedConfig) {
        acc.push(drmPersistedConfig);
      } else {
        acc.push(...drmGroup);
      }
      return acc;
    },
    [],
  );
}

/**
 * Method that filters a list of drms based on the array of preferences we defined.
 * @param deviceType device we are on
 * @param drmList list of drm we want to filter
 * @returns filtered drmContextList by the array of preferences
 */
export function filterUnwantedSupportedDrms(
  deviceType: BusinessTypes.DEVICE_TYPES,
  drmList: ISupportedDRM[],
): ISupportedDRM[] {
  const cencPriorityArray =
    CENC_KEY_SYSTEMS_PRIORITY_SPECIFIC[deviceType] ??
    CENC_KEY_SYSTEMS_PRIORITY_DEFAULT;
  return drmList.filter((drm) => cencPriorityArray.includes(drm.keySystem));
}

/**
 * @param drmA first Drm we want to compare
 * @param drmB second Drm we want to compare
 * @param deviceType type of the device
 * @param CENC_KEY_SYSTEMS_PRIORITY CENC_KEY_SYSTEMS_PRIORITY
 * @returns Drm by priority
 */
export function drmsCompareByPriority(
  drmA: TKeySystem,
  drmB: TKeySystem,
  deviceType: BusinessTypes.DEVICE_TYPES,
  CENC_KEY_SYSTEMS_PRIORITY?: string[],
): number {
  // if we receive from onlineConfig an array of priorities
  // we take this one instead of the default ones defined
  // with deviceType.
  if (CENC_KEY_SYSTEMS_PRIORITY) {
    return (
      CENC_KEY_SYSTEMS_PRIORITY.indexOf(drmA) -
      CENC_KEY_SYSTEMS_PRIORITY.indexOf(drmB)
    );
  }

  const cencPriorityArray =
    CENC_KEY_SYSTEMS_PRIORITY_SPECIFIC[deviceType] ??
    CENC_KEY_SYSTEMS_PRIORITY_DEFAULT;
  return cencPriorityArray.indexOf(drmA) - cencPriorityArray.indexOf(drmB);
}

interface ISortDrmContextListOptions {
  drmContextList: ISupportedDRM[];
  deviceType: BusinessTypes.DEVICE_TYPES;
  CENC_KEY_SYSTEMS_PRIORITY?: string[];
  platform?: TPlatform;
}
/**
 * @param arg0 the object containing drmContextList, deviceType, CENC_KEY_SYSTEMS_PRIORITY and platform
 * @param arg0.drmContextList List of Drm Context
 * @param arg0.deviceType type of the device
 * @param arg0.CENC_KEY_SYSTEMS_PRIORITY CENC_KEY_SYSTEMS_PRIORITY
 * @returns list of Drm context ordered
 */
export function sortDrmContextList({
  drmContextList,
  deviceType,
  CENC_KEY_SYSTEMS_PRIORITY,
}: ISortDrmContextListOptions): ISupportedDRM[] {
  // filter supported drm list based on the array of preference we define.
  const filteredDrmContextList = filterUnwantedSupportedDrms(
    deviceType,
    drmContextList,
  );

  // no need to order the list if there's only one drm we want to use in the supported ones
  if (filteredDrmContextList.length === 1) {
    return filteredDrmContextList;
  }

  // order drm supported list by preference
  return filteredDrmContextList.sort((drmContextA, drmContextB) =>
    drmsCompareByPriority(
      drmContextA.keySystem,
      drmContextB.keySystem,
      deviceType,
      CENC_KEY_SYSTEMS_PRIORITY,
    ),
  );
}

/**
 * @param deviceType type of the device
 * @param shouldPersistLicense config param whether or not we should persist license
 * @returns the necessary configuration for getCompatibleDRMConfigurations() Prober tool
 */
export function constructConfig(
  deviceType: BusinessTypes.DEVICE_TYPES,
  shouldPersistLicense?: boolean,
): Array<{
  type: string;
  configuration: MediaKeySystemConfiguration;
}> {
  const cencKeySystemPriorityArray =
    CENC_KEY_SYSTEMS_PRIORITY_SPECIFIC[deviceType] ??
    CENC_KEY_SYSTEMS_PRIORITY_DEFAULT;

  // EME API (DRM on browsers) implementation are known to be buggy.
  // Here we will propose a second iteration with no asked "capabilities"
  // just in case the browser's implementation refuses the ones we ask
  // for.
  const noCapabilities = deviceType === DESKTOP_MOBILE;

  const configurations: Array<{
    type: string;
    configuration: MediaKeySystemConfiguration;
  }> = [];

  cencKeySystemPriorityArray.forEach((keySystem) => {
    // we can either receive 1, 2 or 3 configurations for the same keysystem:
    // one with the persistent-licence options, one without them and one without
    // audio/video capabilities
    const keySystemConfigurations = getKeySystemConfigurations(
      keySystem,
      shouldPersistLicense,
      noCapabilities,
    );
    keySystemConfigurations.forEach((configuration) => {
      configurations.push({
        type: keySystem,
        configuration,
      });
    });
  });

  return configurations;
}

/**
 * @param drmConfigs drm configs
 * @returns the correct drm supported on the current browser given an array of MediaKeySystemConfiguration object.
 */
export function determineSupportedDrm(
  drmConfigs: ICompatibleKeySystem[],
): ISupportedDRM[] {
  return drmConfigs.reduce<ISupportedDRM[]>((acc, currentConfig) => {
    if (currentConfig.compatibleConfiguration) {
      const keySystem = currentConfig.type as TKeySystem;
      const name = keySystemToDrmName(keySystem);
      const robustnesses = getRobustnessesFromKeySystemConfiguration(
        currentConfig.compatibleConfiguration,
      );
      acc.push({
        keySystem,
        name,
        robustnesses,
        canPersistLicense:
          !!currentConfig.configuration.sessionTypes?.includes(
            'persistent-license',
          ),
        ...(name === DRM_TYPES.WIDEVINE && {
          securityLevel: getWidevineSecurityLevel(robustnesses),
        }),
      });
    }
    return acc;
  }, []);
}

/**
 * @param deviceType type of device
 * @description Safari that implement Fairplay doesnt support requestMediaKeySystemAccess API,
 * instead, we have a direct access to the media keys thanks to an other API
 * @returns if the fairplay DRM is implemented on the browser
 */
export function isFairplayDrmSupported(
  deviceType: BusinessTypes.DEVICE_TYPES,
): boolean {
  if (deviceType === PLAYSTATION_4) {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const safariMediaKeys = window.WebKitMediaKeys;

  return (
    safariMediaKeys &&
    safariMediaKeys.isTypeSupported &&
    safariMediaKeys.isTypeSupported(FAIRPLAY, 'video/mp4')
  );
}

/**
 * @param robustness value of robustness
 * @returns the security level depending on the robustness
 */
export function getSecurityLevelFromRobustness(
  robustness: string,
): TSecurityLevels | undefined {
  switch (robustness) {
    case WIDEVINE_ROBUSTNESSES[0]:
      return L1;
    case WIDEVINE_ROBUSTNESSES[1]:
      return L2;
    case WIDEVINE_ROBUSTNESSES[2]:
      return L2;
    case WIDEVINE_ROBUSTNESSES[3]:
      return L3;
    case WIDEVINE_ROBUSTNESSES[4]:
      return L3;
    case PLAYREADY_ROBUSTNESSES[0]:
      return SL3000;
    case PLAYREADY_ROBUSTNESSES[1]:
      return SL2000;
    default:
      return undefined;
  }
}
