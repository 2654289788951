import { createSelector } from 'reselect';
import { castCurrentLanguageSelector, castCurrentSubtitleSelector, } from './castSelector';
import { configPreferredLanguageSelector, configPreferredSubtitleSelector, } from './configSelector';
import { currentLanguageSelector, currentSubtitleSelector, livePreferredLanguageSelector, livePreferredSubtitleSelector, vodPreferredLanguageSelector, vodPreferredSubtitleSelector, } from './coreSelector';
import { isLiveSelector } from './dataSelector';
/**
 * @returns {function(object): object}
 */
export var preferredLanguageSelector = createSelector(vodPreferredLanguageSelector, livePreferredLanguageSelector, isLiveSelector, configPreferredLanguageSelector, function (vodPreferredLanguage, livePreferredLanguage, isLive, configPreferredLanguage) {
    var preferredLanguage = isLive
        ? livePreferredLanguage
        : vodPreferredLanguage;
    return configPreferredLanguage || preferredLanguage;
});
/**
 * @returns {function(object): ?object}
 */
export var preferredSubtitleSelector = createSelector(vodPreferredSubtitleSelector, livePreferredSubtitleSelector, isLiveSelector, configPreferredSubtitleSelector, function (vodPreferredSubtitle, livePreferredSubtitle, isLive, configPreferredSubtitle) {
    var preferredSubtitle = isLive
        ? livePreferredSubtitle
        : vodPreferredSubtitle;
    // null is a valid value for configPreferredSubtitle
    return configPreferredSubtitle !== undefined
        ? configPreferredSubtitle
        : preferredSubtitle;
});
/**
 * @returns {function(object): ?object}
 */
export var preferredCastLanguageSelector = createSelector(castCurrentLanguageSelector, currentLanguageSelector, configPreferredLanguageSelector, function (castCurrentLanguage, currentLanguage, configPreferredLanguage) {
    return castCurrentLanguage || currentLanguage || configPreferredLanguage;
});
/**
 * @returns {function(object): ?object}
 */
export var preferredCastSubtitleSelector = createSelector(castCurrentSubtitleSelector, currentSubtitleSelector, function (castCurrentSubtitle, currentSubtitle) {
    return castCurrentSubtitle === undefined ? currentSubtitle : castCurrentSubtitle;
});
