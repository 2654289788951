import { createRoot } from 'react-dom/client';

import {
  BusinessTypes,
  DrmTypes,
  filePaths,
  OnePlayerType,
  StbDrmServerCertificates,
} from '@canalplus/oneplayer-constants';
import {
  deepExtend,
  getBaseFromDocumentCurrentScript,
  getDrmContext,
  getOriginFromDocumentCurrentScript,
  IsChromeWithOldWidevineVersion,
  isDrmNeeded,
  isDrmNeedsCertificate,
  isModernMacOS,
  isSupportedBrowser,
  logger,
  parseConfig,
  parseCredentials,
  sortDrmContextList,
  strToBytes,
} from '@canalplus/oneplayer-utils';
import {
  fetchConfig,
  fetchServerCertificate,
  getHapiHeadersInfo,
} from '@canalplus/oneplayer-webservices';

import getPhrases from '../utils/i18n/getPhrases';

import triggerApi from '../ui/utils/api';
import axiosSetup from '../utils/axiosSetup';
import { DEBUGModifyConfig } from '../utils/debug';
import InitErrorManager from '../utils/InitErrorManager';
import { createInstance, removeInstance } from '../utils/instance';
import { setupLocalProxy } from '../utils/proxy';

import {
  spinnerRender,
  spinnerUnmount,
} from '../ui/components/container/common/SpinnerDisplayer';
import CanalPlayer from './canal';

// load oneplayer-shared-components library CSS
import '@canalplus/oneplayer-shared-components/dist/css/index.css';

const {
  DRM_TYPES: { FAIRPLAY, NAGRA },
} = DrmTypes;
const { DEFAULT_BASE } = filePaths;

// retrieve base URL for webpack chunks
// must be done now before everything else
// or document.currentScript will be null once we are running the scripts
const baseFromScript = getBaseFromDocumentCurrentScript();
const originFromScript = getOriginFromDocumentCurrentScript();

if (__DEV__) {
  logger.setLevel('LOG');
} else {
  logger.setLevel('ERROR');
}

// Setup local proxy
if (__LOCAL__) {
  setupLocalProxy();
}

axiosSetup();

const {
  CANAL_PREMIUM_LIVE,
  CANAL_PREMIUM_CATCHUP,
  CANAL_LIGHT_LIVE,
  CANAL_LIGHT_CATCHUP,
  CANAL_PREMIUM_MULTI,
} = OnePlayerType;

const {
  SECURITY_LEVELS: { SL3000, SL2000 },
} = BusinessTypes;

// Default customer service link if not available in config
const DEFAULT_CUSTOMER_SERVICE_LINK = 'https://client.canalplus.com/';
class OnePlayer {
  static __version__ = __ONE_PLAYER_UI_VERSION__;

  static async isSupported(config) {
    const supportedCencDrms = await getDrmContext(config.deviceType);
    const preferredSupportedCencDrmName = supportedCencDrms?.[0]?.name;
    const isSupportedForCurrentEnv = [
      config.platform === 'hapi' && preferredSupportedCencDrmName === FAIRPLAY,
      ['live', 'multi'].includes(config.platform) &&
        preferredSupportedCencDrmName === FAIRPLAY &&
        isModernMacOS(navigator.userAgent),
      !!preferredSupportedCencDrmName && !IsChromeWithOldWidevineVersion(),
    ].some((elem) => !!elem);

    if (isSupportedForCurrentEnv) {
      return true;
    }
    throw new Error(
      "Attention : OnePlayer Premium n'est pas compatible avec votre navigateur",
    );
  }

  /**
   * Check if the browser is in the supported browser list
   * Compability url : https://browserl.ist/?q=%3E+1%25%2C+not+ie+%3E+0%2C+not+op_mini+all%2C+chrome+%3E%3D+66%2C+last+2+Safari+versions
   * @return {boolean}
   */
  static isSupportedBrowser() {
    return isSupportedBrowser();
  }

  /**
   * Check if the browser can decrypt content with Drm
   * @param {object} config
   * @return {promise}
   */
  static async isSupportedDrm(config) {
    const supportedCencDrms = await getDrmContext(config.deviceType);
    const preferredSupportedCencDrmName = supportedCencDrms?.[0]?.name;
    const isSupportedForCurrentEnv = [
      config.platform === 'hapi' && preferredSupportedCencDrmName === FAIRPLAY,
      config.platform === 'live' &&
        preferredSupportedCencDrmName === FAIRPLAY &&
        isModernMacOS(navigator.userAgent),
      preferredSupportedCencDrmName && !IsChromeWithOldWidevineVersion(),
    ].some((elem) => !!elem);

    if (isSupportedForCurrentEnv) {
      return true;
    }
    throw new Error(
      'Votre navigateur internet ne permet pas de décrypter le contenu',
    );
  }

  /**
   * @param {object} config
   * @returns {bool}
   */
  static shouldFetchCertificate(config) {
    return Boolean(
      config.drmNeeded &&
        isDrmNeedsCertificate(config.drmEnv) &&
        config?.drm?.certificates?.[config.drmEnv],
    );
  }

  static getInternalsVersion() {
    return {
      coreVersion: __ONE_PLAYER_CORE_VERSION__,
      coreHlsVersion: __ONE_PLAYER_CORE_HLS_VERSION__,
      uiVersion: __ONE_PLAYER_UI_VERSION__,
    };
  }

  constructor(container, config = null, credentials = {}) {
    // Note1: Only container is mandatory
    // If config is null, we won't try to load a content, and wait for
    // a call to loadVideo to do so

    // Note2: we imediately create the video element and call load
    // this allow us to launch the playback of the content at anytime
    // espacially on devices / browsers where play without a click event is blocked
    // (safari > 11, safari ios, chrome android, and chrome)
    this.videoElement = document.createElement('video');

    this.player = null;
    /**
     * When set, store both the core player class (as a "class" property) and
     * the core player instance (as an "instance" property) that is currently
     * saved.
     *
     * `null` if no core player has been saved.
     */
    this.savedCorePlayerInfo = null;
    this.drmContext = null;
    this.initialized = false;
    this.adData = {};
    this.DRM_SERVER_CERTIFICATE = null;

    if (container) {
      this.setContainer(container);
      this.setRoot(container);
    }

    if (config) {
      // If a config is passed, we can load a content
      this.loadVideo(config, credentials, container);
    }
  }

  /**
   * Initiates the player asynchronously
   *
   * @param {IAPIConfig} config A sanitized config and parsed
   * @param {object} credentials
   */
  async initAsync(config) {
    // do not init the player twice
    if (this.initialized) {
      return;
    }

    this.renderSpinner(config);

    // Deal with init errors (triggered before redux is instanciated)
    this.initErrorManager = new InitErrorManager({
      uid: this.uid,
      exitButton: config.params.exitButton,
      isTvPlayer: config.params.theme === 'tv',
    });

    // mark the player has initialized
    this.initialized = true;
    logger.log('OnePlayer API > Init. The player is initialized');
  }

  renderSpinner({ variant }) {
    if (!this.ignoreSpinner) {
      spinnerRender(this.root, variant);
    }
  }

  disposeCorePlayer = () => {
    if (this.savedCorePlayerInfo) {
      logger.log('OnePlayer API > call disposeCorePlayer()');
      this.savedCorePlayerInfo.instance.dispose?.();
      this.savedCorePlayerInfo = null;
    }
  };

  destroySpinner() {
    this.ignoreSpinner = true;
    spinnerUnmount(this.root);
  }

  async setConfig(config) {
    logger.debug('OnePlayer API > setConfig', config);
    if (__DEV__) {
      __webpack_public_path__ = config?.base || baseFromScript; // eslint-disable-line camelcase
    } else {
      __webpack_public_path__ = baseFromScript || DEFAULT_BASE; // eslint-disable-line camelcase
    }

    const phrases = await getPhrases(config.uiLanguage);
    this.initErrorManager.setI18nConfig({
      phrases,
      local: config.uiLanguage,
    });

    this.config = config;
  }

  setContainer(container) {
    if (typeof container === 'string') {
      this.container = document.getElementById(container);
    } else {
      this.container = container;
    }
  }

  setRoot(container) {
    if (typeof container === 'string') {
      this.root = createRoot(document.getElementById(container));
    } else {
      this.root = createRoot(container);
    }
  }

  setContainerStyleAndId() {
    requestAnimationFrame(() => {
      this.container.setAttribute('id', `oneplayer-container-${this.uid}`);
      this.container.style.width = '100%';
      this.container.style.height = '100%';
      this.container.style.position = 'relative';
    });
  }

  resetContainerStyleAndId() {
    requestAnimationFrame(() => {
      this.container.setAttribute('id', '');
      this.container.style.width = '';
      this.container.style.height = '';
      this.container.style.position = '';
      this.container.style.backgroundColor = '';
    });
  }

  setCredentials(credentials) {
    this.credentials = credentials;
  }

  saveCorePlayer = ({
    corePlayerType,
    corePlayerInstance,
    corePlayerClass,
  }) => {
    this.savedCorePlayerInfo = {
      type: corePlayerType,
      instance: corePlayerInstance,
      class: corePlayerClass,
      videoElement: corePlayerInstance.getVideoElement?.(),
    };
  };

  getSavedCorePlayer = (videoElement) => {
    // before retrieving the corePlayer we saved earlier, we make sure that we are
    // still manipulating the same videoElement to avoid any de-sync.
    if (videoElement === this.savedCorePlayerInfo?.videoElement) {
      return this.savedCorePlayerInfo;
    }
    return undefined;
  };

  /**
   * Check if configuration has changed to fetch or not new config file
   * Return a full config object
   */
  async ensureConfig(config) {
    logger.debug('OnePlayer API > checkConfig', config);

    const currentConfig = this.config;
    const newContext = config.context ?? currentConfig.context;
    const newEnv = config.env ?? currentConfig.env;

    let aggregatedConfig;

    let configFile;

    try {
      configFile = await fetchConfig({
        context: newContext,
        env: newEnv,
        offerZone: config.params.offerZone,
        deviceType: config.deviceType,
        configBaseUrl: config.configBaseUrl,
        originFromScript,
      });
      configFile.ads = {
        ...configFile.ads,
        adData: this.adData,
      };
    } catch (error) {
      logger.error(
        'OnePlayer API > Fatal error: Could not fetch online configuration or build configuration.',
        error,
      );
      this.destroySpinner();
      this.initErrorManager.trigger({
        category: 'internal',
        code: 'config',
        i18nKey: 'errors.internal.config',
      });
    }

    if (!this.drmContext) {
      if (configFile.drm?.context) {
        this.drmContext = [configFile.drm.context];
      } else {
        this.drmContext = await getDrmContext(
          config.deviceType,
          configFile?.drm?.CENC_KEY_SYSTEMS_PRIORITY,
          config.platform,
          configFile?.params?.shouldPersistLicense,
        );
      }
    } else {
      // reuse same DRM context if it has already been determined on one player instance,
      // only reorder keySystems based on preference (as it could change depending on the platform)
      this.drmContext = sortDrmContextList({
        drmContextList: this.drmContext,
        deviceType: config.deviceType,
        CENC_KEY_SYSTEMS_PRIORITY: configFile?.drm?.CENC_KEY_SYSTEMS_PRIORITY,
        platform: config.platform,
      });
    }

    const preferredSupportedDrm =
      this.drmContext?.length > 0 ? this.drmContext[0] : null;

    this.drmEnv = preferredSupportedDrm?.name || null;
    const drmSecurityLevels = preferredSupportedDrm?.robustnesses || null;
    const keySystem = preferredSupportedDrm?.keySystem || null;
    const drmNeeded = isDrmNeeded(config.platform);
    const canPersistLicense = !!preferredSupportedDrm?.canPersistLicense;
    let securityLevel = preferredSupportedDrm?.securityLevel || null;
    if (keySystem === 'com.microsoft.playready.recommendation') {
      const { robustnesses } = preferredSupportedDrm;
      // if SL3000 is supported the robustnesses should look like this: ['2000','3000']
      // if not, there should only be '2000' in the array.
      const isSl3000 = robustnesses.find((robustness) => robustness === '3000');
      securityLevel = isSl3000 ? SL3000 : SL2000;
    }

    const initialConfig = {
      uid: this.uid,
      drmEnv: this.drmEnv,
      drmSecurityLevels,
      drmNeeded,
      keySystem,
      supportedDrms: this.drmContext,
      canPersistLicense,
      securityLevel,
      originFromScript,
      ...config,
    };

    aggregatedConfig = deepExtend(configFile, initialConfig, config, {
      drmNeeded: isDrmNeeded(config.platform),
      customerServiceLink:
        configFile.customerServiceLink ?? DEFAULT_CUSTOMER_SERVICE_LINK,
      params: {
        enableAntiAdSkip:
          typeof config.params.enableAntiAdSkip === 'boolean'
            ? config.params.enableAntiAdSkip
            : configFile.params.enableAntiAdSkip,
      },
      featureFlags: {
        // TODO: Remove this when config is updated (issue #2169)
        shouldShowPlaybackRate:
          configFile.featureFlags?.shouldShowPlaybackRate ||
          configFile.featureFlags?.showPlaybackRate ||
          false,
        shouldShowAudioOnly:
          configFile.featureFlags?.shouldShowAudioOnly ||
          configFile.featureFlags?.showExperimentalFeatures ||
          false,
      },
      contextMenu: {
        // TODO: Remove this when config is updated (issue #2169)
        shouldShowDebug:
          configFile.contextMenu?.shouldShowDebug ??
          configFile.contextMenu?.showDebug,
        shouldShowShortcuts:
          configFile.contextMenu?.shouldShowShortcuts ??
          configFile.contextMenu?.showShortcuts,
        shouldShowQualityControl:
          configFile.contextMenu?.shouldShowQualityControl ??
          configFile.contextMenu?.showQualityControl,
      },
    });

    if (__DEV__) {
      aggregatedConfig = DEBUGModifyConfig(aggregatedConfig);
    }

    if (
      this.DRM_SERVER_CERTIFICATE === null &&
      OnePlayer.shouldFetchCertificate(aggregatedConfig)
    ) {
      const certificateUrl =
        aggregatedConfig.drm?.certificates?.[aggregatedConfig.drmEnv];
      logger.debug(
        'OnePlayer API > ensureConfig - Fetch certificate',
        certificateUrl,
      );
      this.DRM_SERVER_CERTIFICATE =
        await fetchServerCertificate(certificateUrl);
    } else if (aggregatedConfig.drmEnv === NAGRA) {
      // STB (Set Top Boxes) use Nagra
      // For these devices, we get the certificate from the local object StbDrmServerCertificates and not from an external source
      // This exception is not ready to change for now as it depends on multiple teams
      this.DRM_SERVER_CERTIFICATE = strToBytes(
        StbDrmServerCertificates[aggregatedConfig.deviceType],
      );
    }

    // NOTE: drmCertificate property is not assign with deepExtend because it
    // cast the type of the drmCertificate from ArrayBuffer type to an object type
    aggregatedConfig.drmCertificate = this.DRM_SERVER_CERTIFICATE;

    logger.debug(
      'OnePlayer API > ensureConfig > config updated to',
      aggregatedConfig,
    );

    return aggregatedConfig;
  }

  isDirectFile() {
    return this.config.platform === 'directfile';
  }

  getType() {
    switch (this.config.platform) {
      case 'multi':
        return 'multi';
      case 'hapi':
      case 'directfile':
        return 'catchup';
      case 'live':
      default:
        return 'live';
    }
  }

  getEnv() {
    return this.isDirectFile() ? 'light' : 'premium';
  }

  getBundle() {
    logger.debug('OnePlayer API > getBundle');

    const type = this.getType();
    const env = this.getEnv();

    // TODO: do it properly
    this.config.playerType = type;

    return `canal-${env}-${type}`;
  }

  async choosePlayer() {
    logger.debug('OnePlayer API > choosePlayer', this.bundle);

    switch (this.bundle) {
      case CANAL_PREMIUM_LIVE:
        this.player = new CanalPlayer(
          this.root,
          this.config,
          this.credentials,
          null,
          this.savedCorePlayerInfo?.instance,
          this.videoElement,
          this.saveCorePlayer,
          this.getSavedCorePlayer,
        );
        break;
      case CANAL_PREMIUM_CATCHUP:
        this.player = new CanalPlayer(
          this.root,
          this.config,
          this.credentials,
          null,
          this.savedCorePlayerInfo?.instance,
          this.videoElement,
          this.saveCorePlayer,
          this.getSavedCorePlayer,
        );
        break;
      case CANAL_LIGHT_LIVE:
        this.player = new CanalPlayer(
          this.root,
          this.config,
          this.credentials,
          null,
          this.savedCorePlayerInfo?.instance,
          this.videoElement,
          this.saveCorePlayer,
          this.getSavedCorePlayer,
        );
        break;
      case CANAL_LIGHT_CATCHUP:
        this.player = new CanalPlayer(
          this.root,
          this.config,
          this.credentials,
          null,
          this.savedCorePlayerInfo?.instance,
          this.videoElement,
          this.saveCorePlayer,
          this.getSavedCorePlayer,
        );
        break;
      case CANAL_PREMIUM_MULTI:
        this.player = new CanalPlayer(
          this.root,
          this.config,
          this.credentials,
          null,
          this.savedCorePlayerInfo?.instance,
          this.videoElement,
          this.saveCorePlayer,
          this.getSavedCorePlayer,
        );
        break;
      default:
        logger.error(
          'OnePlayer API > type of player is unknown, verify parameters of instanciation',
        );
        this.initErrorManager.trigger({
          category: 'internal',
          code: 'config',
          i18nKey: 'errors.internal.config',
        });
        break;
    }
  }

  /**
   *
   * Public API
   *
   */

  /**
   * LoadVideo, is the engine of the API.
   * This method will be called each time the user want to launch a playback.
   * She is busy gathering all the primary data such as config or drm config.
   *
   * @param configArg
   * @returns {Promise.<void>}
   */
  loadVideo = async (configArg, credentialsArg, container) => {
    this.uid = createInstance(this);
    if (container) {
      if (!this.root) {
        this.setRoot(container);
      }
      this.setContainer(container);
    }
    this.setContainerStyleAndId();

    logger.log('OnePlayer API > call loadVideo()', configArg);
    const config = parseConfig(configArg);
    if (config === null) {
      throw new Error(
        'Something went wrong, due to a bad configuration options.',
      );
    }
    const credentials = parseCredentials(credentialsArg ?? {});
    this.setCredentials(credentials);

    // init the player if necessary
    await this.initAsync(config);

    // Save the new config to use
    const configEnsured = await this.ensureConfig(config);
    await this.setConfig(configEnsured);

    // Define the bundle's name
    const newBundle = this.getBundle();

    // We need to reformat the content inside the config for MULTI
    if (newBundle === CANAL_PREMIUM_MULTI) {
      this.config = {
        ...this.config,
        content: this.config.content[0].content,
        multiContent: this.config.content,
      };
    }

    // La fonction loadVideo a été appelée et l'analyse des paramètres montre que le lecteur
    // à instancier est différent de l'instance courante
    if (newBundle !== this.bundle) {
      this.bundle = newBundle;

      await this.choosePlayer();

      // Try to trigger callback for `onReady` event
      triggerApi(this.uid, 'onReady', {});
    } else {
      this.player.load(
        this.config,
        this.credentials || {},
        null,
        this.saveCorePlayer,
        this.getSavedCorePlayer,
        this.root,
      );
    }
  };

  play() {
    logger.log('OnePlayer API > call play()');
    this.player.play();
  }

  pause() {
    logger.log('OnePlayer API > call pause()');
    this.player.pause();
  }

  resumeAd() {
    logger.log('OnePlayer API > call resumeAd()');
    this.player.resumeAd();
  }

  pauseAd() {
    logger.log('OnePlayer API > call pauseAd()');
    this.player.pauseAd();
  }

  stop() {
    logger.log('OnePlayer API > call stop()');
    this.player.stop();
  }

  stopLiveTv() {
    logger.log('OnePlayer API > call stopLiveTv()');
    this.player.stopLiveTv();
  }

  seekTo(s) {
    logger.log('OnePlayer API > call seekTo()', s);
    this.player.seekTo(s);
  }

  setKeyPressListenerStatus(newStatus) {
    logger.log('OnePlayer API > call setKeyPressListenerStatus()', newStatus);
    this.player.setKeyPressListenerStatus(newStatus);
  }

  getAvailableLanguages() {
    logger.log('OnePlayer API > call getAvailableLanguages()');
    return this.player.getAvailableLanguages();
  }

  getAvailableSubtitles() {
    logger.log('OnePlayer API > call getAvailableSubtitles()');
    return this.player.getAvailableSubtitles();
  }

  getCurrentLanguage() {
    logger.log('OnePlayer API > call getCurrentLanguage()');
    return this.player.getCurrentLanguage();
  }

  getCurrentTime() {
    logger.log('OnePlayer API > call getCurrentTime()');
    return this.player.getCurrentTime();
  }

  getConfig() {
    logger.log('OnePlayer API > call getConfig()');
    return this.player.getConfig();
  }

  getCurrentProgram() {
    logger.log('OnePlayer API > call getCurrentProgram()');
    return this.player.getCurrentProgram();
  }

  getCurrentChannel() {
    logger.log('OnePlayer API > call getCurrentChannel()');
    return this.player.getCurrentChannel();
  }

  getContentInfo() {
    logger.log('OnePlayer API > call getContentInfo()');
    return this.player.getContentInfo();
  }

  getEndTime() {
    logger.log('OnePlayer API > call getEndTime()');
    return this.player.getEndTime();
  }

  getStartTime() {
    logger.log('OnePlayer API > call getStartTime()');
    return this.player.getStartTime();
  }

  getCurrentSubtitle() {
    logger.log('OnePlayer API > call getCurrentSubtitle()');
    return this.player.getCurrentSubtitle();
  }

  getVideoDuration() {
    logger.log('OnePlayer API > call getVideoDuration()');
    return this.player.getVideoDuration();
  }

  getLiveTime() {
    logger.log('OnePlayer API > call getLiveTime()');
    return this.player.getLiveTime();
  }

  getFullscreen() {
    logger.log('OnePlayer API > call getFullscreen()');
    return this.player.getFullscreen();
  }

  getVolume() {
    logger.log('OnePlayer API > call getVolume()');
    return this.player.getVolume();
  }

  getPlayerState() {
    logger.log('OnePlayer API > call getPlayerState()');
    return this.player.getPlayerState();
  }

  getPlaybackRate() {
    logger.log('OnePlayer API > call getPlaybackRate()');
    return this.player.getPlayBackRate();
  }

  getServerLiveTimeOffset() {
    logger.log('OnePlayer API > call getServerLiveTimeOffset');
    return this.player.getServerLiveTimeOffset();
  }

  getAvailableChannels(genre) {
    logger.log(`OnePlayer API > call getAvailableChannels with genre ${genre}`);
    return this.player.getAvailableChannels(genre);
  }

  getAllProgramGenres() {
    logger.log('OnePlayer API > call getAllProgramGenres');
    return this.player.getAllProgramGenres();
  }

  isLivePremium() {
    logger.log('OnePlayer API > call isLivePremium()');
    return this.player.isLivePremium();
  }

  isTvShow() {
    logger.log('OnePlayer API > call isTvShow()');
    return this.player.isTvShow();
  }

  setAdData(adParameters) {
    logger.debug('OnePlayer API > setAdData', adParameters);
    this.adData = adParameters;
  }

  setLanguage(language) {
    logger.log('OnePlayer API > call setLanguage()', language);
    this.player.setLanguage(language);
  }

  setSubtitle(subtitle) {
    logger.log('OnePlayer API > call setSubtitle()', subtitle);
    this.player.setSubtitle(subtitle);
  }

  // We support multiple kind of arguments for zapTo()
  // The legacy version is using two params : the first param an epgId and the second param  startAt
  // The newest version, has only one param which is an object like { channelNumber, epgId, startAt }
  zapTo(channelDetails, legacyStartAt) {
    let channelNumber;
    let epgId;
    let startAt;

    if (typeof channelDetails === 'object') {
      channelNumber = channelDetails.channelNumber;
      epgId = channelDetails.epgId;
      startAt = channelDetails.startAt;
    } else {
      // If the legacy version is used (using two params)
      epgId = channelDetails;
      startAt = legacyStartAt;

      logger.warn(
        'OnePlayer API > warning',
        'zapTo(epgId, startAt) call is deprecated, please use zapTo({ channelNumber, epgId, startAt }) instead',
      );
    }

    logger.log('OnePlayer API > call zapTo()', channelNumber, epgId, startAt);
    this.player.zapTo?.({ channelNumber, epgId, startAt });
  }

  zapToNextChannel() {
    logger.log('OnePlayer API > call to zapToNextChannel()');
    this.player.zapToNextChannel();
  }

  zapToPreviousChannel() {
    logger.log('OnePlayer API > call to zapToPreviousChannel()');
    this.player.zapToPreviousChannel();
  }

  goToNextEpisode() {
    logger.log('OnePlayer API > call to goToNextEpisode()');
    this.player.goToNextEpisode();
  }

  goToLive() {
    logger.log('OnePlayer API > call goToLive()');
    this.player.goToLive();
  }

  startCasting() {
    logger.log('OnePlayer API > call startCasting()');
    this.player.startCasting();
  }

  restart() {
    logger.log('OnePlayer API > call restart()');
    this.player.restart();
  }

  setVolume(volume) {
    logger.log('OnePlayer API > call setVolume()', volume);
    this.player.setVolume(volume);
  }

  mute() {
    logger.log('OnePlayer API > call mute()');
    this.player.mute();
  }

  unmute() {
    logger.log('OnePlayer API > call unmute()');
    this.player.unmute();
  }

  destroy() {
    logger.log('OnePlayer API > call destroy()');
    if (this.player) {
      this.player.destroy();
      this.root = undefined;
    }
    if (this.initErrorManager) {
      this.initErrorManager.destroy();
    }
    this.resetContainerStyleAndId();
    removeInstance(this.uid);
  }

  addListener(eventType, callback) {
    logger.log(`OnePlayer API > call addListener(${eventType})`);

    if (!this.config.events) {
      this.config.events = {};
    }

    this.config.events[eventType] = callback;
  }

  removeListener(eventType) {
    logger.log(`OnePlayer API > call removeListener(${eventType})`);

    if (this.config.events && this.config.events[eventType]) {
      delete this.config.events[eventType];
    }
  }

  static async getHapiHeadersInfo(offerZone, deviceType) {
    logger.log(
      `OnePlayer API > call getHapiHeadersInfo(${offerZone}, ${deviceType})`,
    );
    return getHapiHeadersInfo(offerZone, deviceType);
  }
}

export { OnePlayer };
