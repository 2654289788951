import { createSelector } from 'reselect';
import { configPreferredLanguageSelector, configPreferredSubtitleSelector, } from './configSelector';
import { livePreferredLanguageSelector, livePreferredSubtitleSelector, vodPreferredLanguageSelector, vodPreferredSubtitleSelector, } from './coreSelector';
export var multiSelector = function (state) { return state.multi; };
/**
 * @returns {function(object): Array}
 */
export var multiLiveDataSelector = createSelector(multiSelector, function (multi) { return multi.live; });
export var multiTrackPreferencesSelector = createSelector(livePreferredLanguageSelector, vodPreferredLanguageSelector, configPreferredLanguageSelector, livePreferredSubtitleSelector, vodPreferredSubtitleSelector, configPreferredSubtitleSelector, function (liveLanguage, vodLanguage, configLanguage, liveSubtitle, vodSubtitle, configSubtitle) { return ({
    live: {
        audio: liveLanguage,
        subtitle: liveSubtitle,
    },
    vod: {
        audio: vodLanguage,
        subtitle: vodSubtitle,
    },
    config: {
        audio: configLanguage,
        subtitle: configSubtitle,
    },
}); });
