import { logger } from '@canalplus/oneplayer-utils';

import {
  MIDROLL_AD_TYPE,
  POSTROLL_AD_TYPE,
  PREROLL_AD_TYPE,
} from './constants';
import {
  getAdInfoFromTimeOffset,
  getChildNodesWithoutTexts,
  parseNodeText,
} from './utils';

export default class VMAPResponse {
  constructor(xmlElement) {
    this.trackingEvents = [];
    this.vasts = [];
    this.parse(xmlElement);
  }

  setTrackingEvents(trackingEvents, adType, adStartTime) {
    this.trackingEvents.push({ events: trackingEvents, adType, adStartTime });
  }

  setVasts(adElement, adType, adStartTime) {
    this.vasts.push({ vast: adElement, adType, adStartTime });
  }

  parse(vmapElement) {
    const adBreaks = vmapElement.childNodes;

    for (let i = 0, adBreaksLen = adBreaks.length; i < adBreaksLen; i += 1) {
      const adBreak = adBreaks[i];

      if (adBreak.nodeName === 'vmap:AdBreak') {
        const {
          attributes: { breakType, timeOffset },
        } = adBreak;

        // Only evaluate AdBreak which correspond to preroll, postroll or midroll
        // With breakType 'linear' and timeOffset 'start', 'end', or '(HH:)MM:SS(.ms)'
        const { adType, adStartTime } = getAdInfoFromTimeOffset(timeOffset);

        if (
          breakType.value === 'linear' &&
          ([PREROLL_AD_TYPE, POSTROLL_AD_TYPE].includes(adType) ||
            (adType === MIDROLL_AD_TYPE && adStartTime))
        ) {
          // Get VAST within AdBreak
          const adBreakChildren = adBreak.childNodes;

          for (let a = 0, len = adBreakChildren.length; a < len; a += 1) {
            // Get AdSource
            if (adBreakChildren[a].nodeName === 'vmap:AdSource') {
              // AdSource only has a single child which is a VastAdData
              const vastAdData = getChildNodesWithoutTexts(
                adBreakChildren[a],
              )[0];

              if (vastAdData) {
                // VastAdData only has a single child which is a Vast
                const vast = getChildNodesWithoutTexts(vastAdData)[0];

                // Set vast in vasts list
                this.setVasts(vast, adType, adStartTime);
              } else {
                logger.warn('OnePlayer Ads > VmapAdBreak has no AdSource');
              }
            }

            // Get TrackingEvents within AdBreak
            if (adBreakChildren[a].nodeName === 'vmap:TrackingEvents') {
              const trackingEvents = adBreakChildren[a].childNodes;

              // Parse each tracking event
              const parsedTrackingEvents = [];
              const trackingEventsLength = trackingEvents.length;

              for (let t = 0; t < trackingEventsLength; t += 1) {
                const trackingEvent = trackingEvents[t];
                if (trackingEvent.nodeName === 'vmap:Tracking') {
                  const {
                    attributes: { event },
                  } = trackingEvent;

                  if (
                    event.value === 'breakStart' ||
                    event.value === 'breakEnd'
                  ) {
                    parsedTrackingEvents.push({
                      breakType: event.value,
                      url: parseNodeText(trackingEvent),
                    });
                  }
                }
              }

              // Set tracking events if there are any
              if (parsedTrackingEvents.length > 0) {
                this.setTrackingEvents(
                  parsedTrackingEvents,
                  adType,
                  adStartTime,
                );
              }
            }
          }
        } else {
          logger.warn(
            'OnePlayer Ads > Unsupported VmapAdBreak',
            `breaktype: ${breakType.value}`,
            `timeoffset: ${timeOffset.value}`,
          );
        }
      }
    }
  }
}
