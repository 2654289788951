import smoothscroll from 'smoothscroll-polyfill';

import { BusinessTypes } from '@canalplus/oneplayer-constants';
import { logger, userStorage } from '@canalplus/oneplayer-utils';

const {
  PLATFORMS: { PLATFORM_DIRECTFILE },
} = BusinessTypes;

// Check userAgent for Samsung 2018: if it includes "Tizen 4.0" it means we are on
// a Samsung 2018 (this is the only info we have)
const isSamsung2018 = navigator.userAgent.includes('Tizen 4.0');
// Check userAgent for LG 2019: if it includes "Chrome/53" and "Web0S" it means we are on
// a LG 2019 (this is the only info we have)
const isLG2019 =
  (navigator.userAgent.includes('Chrome/53') ||
    navigator.userAgent.includes('Chr0me/53')) &&
  (navigator.userAgent.includes('Web0S') ||
    navigator.userAgent.includes('WebOS'));
const isOrangeTvKey = navigator.userAgent.includes('StickTV');
const isXbox = navigator.userAgent.includes('Xbox');
// Polyfill required for old browsers
// Like chrome < 61 & PLAYSTATION_4
if (__ES5__ || isSamsung2018 || isLG2019 || isOrangeTvKey || isXbox) {
  smoothscroll.polyfill();
}

// This is our API entry point
// It does also call the renderApp function mounting React/Redux
export default class CanalPlayer {
  static resolveEntryEnv(platform) {
    return [PLATFORM_DIRECTFILE].includes(platform) ? 'light' : 'premium';
  }

  constructor(
    root,
    config,
    credentials = {},
    data = null,
    corePlayer = null,
    videoElement = null,
    saveCorePlayer,
    getSavedCorePlayer,
  ) {
    this.corePlayer = corePlayer;
    this.videoElement = videoElement;
    this.destroyCallback = undefined;

    this.load(
      config,
      credentials,
      data,
      saveCorePlayer,
      getSavedCorePlayer,
      root,
    ).catch((error) => {
      logger.error('OnePlayer > `theme` parameter might be invalid', error);
    });
  }

  async load(
    config,
    credentials,
    data = null,
    saveCorePlayer,
    getSavedCorePlayer,
    root,
  ) {
    // The player client can specify a deviceId, then we save it
    if (credentials.deviceId) {
      await userStorage.setItem('dev', credentials.deviceId);
    }

    // Otherwise we get/generate the default ones
    const { userKeyId, deviceKeyId } = await userStorage.getCredentials();
    // eslint-disable-next-line no-param-reassign
    credentials = {
      ...credentials,
      userKeyId,
      deviceKeyId,
    };

    const {
      platform,
      playerType: type,
      params: { theme },
    } = config;
    const env = CanalPlayer.resolveEntryEnv(platform);

    // renderApp: This is this function that will mount React and ultimately have Redux connected while being at it
    const [{ default: storeApi }, { default: renderApp }] = await Promise.all([
      import(/* webpackChunkName: "storeApi" */ './storeApi'),
      import(/* webpackChunkName: "renderApp" */ '../ui'),
    ]);

    // Instantiate the redux store with the redux-dependant API
    // This will add all the API functions to this instance of CanalPlayer
    // Use a string made of env, type, theme to help load the required middlewares
    // TODO: add capitalize function in utils package
    const capitalizeType = type.charAt(0).toUpperCase() + type.slice(1);
    const capitalizeTheme = theme.charAt(0).toUpperCase() + theme.slice(1);
    storeApi.call(this, {}, [], [`${env}${capitalizeType}${capitalizeTheme}`]);

    renderApp(root, {
      config,
      credentials,
      data,
      corePlayer: this.corePlayer,
      saveCorePlayer,
      getSavedCorePlayer,
      env,
      type,
      theme,
      videoElement: this.videoElement,
      store: this.store,
      onHydration: this.onHydration, // this is the callback called after the store is hydrated with data
      onDestroy: this.onDestroy, // this is the callback called when destroy() is called
    });
  }

  onDestroy = (callback) => {
    if (typeof callback === 'function') {
      this.destroyCallback = callback; // store the callback for later use
    } else {
      this.destroyCallback = undefined;
    }
  };

  destroy() {
    if (typeof this.destroyCallback === 'function') {
      this.destroyCallback();
    }
  }
}
