import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { Spinner } from '@canalplus/oneplayer-shared-components';
import { isSpinnerVisibleSelector } from '@oneplayer/selectors';
import withVariant from '../../../hoc/withVariant';
import VideoWrapperContainer from '../layout/VideoWrapperContainer';
var SpinnerDisplayer = function (_a) {
    var showSpinner = _a.showSpinner, variant = _a.variant, children = _a.children;
    return (_jsxs(Fragment, { children: [children, showSpinner && (_jsx(VideoWrapperContainer, { children: _jsx(Spinner, { variant: variant }) }))] }));
};
// In order to separate concerns one would want to call and mount a spinner
// without having to know the rendering lib (here React)
var spinnerRender = function (root, variant) {
    root.render(_jsx(Spinner, { variant: variant }));
};
var spinnerUnmount = function (root) {
    root.unmount();
};
var mapStateToProps = function (state) { return ({
    showSpinner: isSpinnerVisibleSelector(state),
}); };
var SpinnerDisplayerConnected = connect(mapStateToProps)(withVariant(SpinnerDisplayer));
export { spinnerRender, spinnerUnmount };
export default SpinnerDisplayerConnected;
