import { BusinessTypes } from '@canalplus/oneplayer-constants';
import { logger } from '@canalplus/oneplayer-utils';

import * as Utils from './utils';
import {
  VASTCreativeCompanion,
  VASTCreativeLinear,
  VASTCreativeNonLinear,
} from './VASTCreative';
import VASTExtension from './VASTExtension';

const { SPONSOR_GENRES } = BusinessTypes;

export default class VASTAd {
  static DEFAULT_MAX_PREFERRED_BITRATE = 900000;

  static supportedExtensionTypes = ['AdServer', 'FreeWheel'];

  static sponsorGenre = Object.values(SPONSOR_GENRES);

  static parseExtensionElement(extensionElement) {
    return new VASTExtension(extensionElement);
  }

  static parseLinearElement(linearElement) {
    return new VASTCreativeLinear(linearElement);
  }

  static parseNonLinearAdsElement(nonLinearAdsElement) {
    return new VASTCreativeNonLinear(nonLinearAdsElement);
  }

  static parseCompanionAdsElement(companionAdsElement) {
    return new VASTCreativeCompanion(companionAdsElement);
  }

  static parseErrorElement(errorNode) {
    return Utils.parseNodeText(errorNode);
  }

  static parseImpressionElement(impressionElement) {
    return Utils.parseNodeText(impressionElement);
  }

  static parseCreativesElement(creativesElement) {
    const creativeElements = Utils.childrenByName(creativesElement, 'Creative');
    const creatives = [];

    creativeElements.forEach((creativeElement) => {
      const creativeTypeElements = creativeElement.childNodes;

      for (let i = 0; i < creativeTypeElements.length; i += 1) {
        let creative;

        const creativeTypeElement = creativeTypeElements[i];
        switch (creativeTypeElement.nodeName) {
          case 'Linear': {
            creative = this.parseLinearElement(creativeTypeElement);
            if (creative) {
              creatives.push(creative);
            }
            break;
          }

          case 'NonLinearAds': {
            creative = this.parseNonLinearAdsElement(creativeTypeElement);
            if (creative) {
              creatives.push(creative);
            }
            break;
          }

          case 'CompanionAds': {
            creative = this.parseCompanionAdsElement(creativeTypeElement);
            if (creative) {
              creatives.push(creative);
            }
            break;
          }

          default: {
            const { nodeName } = creativeTypeElement;
            if (nodeName !== '#text') {
              logger.warn(
                `OnePlayer Ads > ${nodeName} is not implemented (CreativeType)`,
              );
            }
          }
        }
      }
    });

    return creatives;
  }

  static parseExtensionsElement(extensionsElement) {
    const extensionElements = Utils.childrenByName(
      extensionsElement,
      'Extension',
    );
    const extensions = [];

    extensionElements.forEach((extensionElement) => {
      const extension = this.parseExtensionElement(extensionElement);
      if (extension) {
        extensions.push(extension);
      }
    });

    return extensions;
  }

  static selectFileByBitrate(mediaFiles = [], maxVideoBitrate) {
    // transform into kbites
    const maxBitrate =
      (maxVideoBitrate ?? VASTAd.DEFAULT_MAX_PREFERRED_BITRATE) / 1000;

    // File with the lowest bitrate comes first
    const sortedMediaFiles = mediaFiles.sort(
      (fileA, fileB) => fileA.bitrate - fileB.bitrate,
    );

    let selectedFile = sortedMediaFiles[0];

    mediaFiles.forEach((file) => {
      if (file.bitrate > selectedFile.bitrate && file.bitrate < maxBitrate) {
        selectedFile = file;
      }
    });

    return selectedFile;
  }

  constructor(xmlElement) {
    this.id = null;
    this.errorURLTemplates = [];
    this.impressionURLTemplates = [];
    this.creatives = [];
    this.extensions = [];

    if (xmlElement) {
      this.parse(xmlElement);
    }
  }

  getLinearCreative() {
    return this.creatives.filter((creative) => creative.type === 'linear')[0];
  }

  getVideoMediaFile(mimeTypes, maxVideoBitrate) {
    const linear = this.getLinearCreative();

    if (!linear) {
      return null;
    }

    const filesByType = linear.mediaFiles.filter((mf) =>
      mimeTypes.includes(mf.mimeType),
    );

    // we take the vpaid in priority if there is one
    const mediaFile =
      filesByType.find((mf) => mf.mimeType === 'application/javascript') ??
      VASTAd.selectFileByBitrate(filesByType, maxVideoBitrate);

    if (!mediaFile) {
      // ### Error 403: coudn't find MediaFile that is supported by this video player
      Utils.trackErrorWithCode(this.errorURLTemplates, 403);
    } else {
      mediaFile.adParameters = linear.adParameters;
    }

    return mediaFile;
  }

  getCampaignId() {
    const adServerExtension = this.extensions.find(
      (extension) =>
        VASTAd.supportedExtensionTypes.includes(extension.type) &&
        !!extension.campaignId,
    );

    if (!adServerExtension) {
      return null;
    }

    return adServerExtension.campaignId;
  }

  getCanSkipAfter() {
    const adServerExtension = this.extensions.find(
      (extension) =>
        VASTAd.supportedExtensionTypes.includes(extension.type) &&
        extension.canSkipAfter != null,
    );

    if (!adServerExtension) {
      return null;
    }

    return adServerExtension.canSkipAfter;
  }

  sortBySponsoring() {
    return this.extensions.sort((a, b) => {
      const isExtTypeSupportedA =
        VASTAd.supportedExtensionTypes.includes(a.type) &&
        VASTAd.sponsorGenre.includes(a?.adGenre);
      const isExtTypeSupportedB =
        VASTAd.supportedExtensionTypes.includes(b.type) &&
        VASTAd.sponsorGenre.includes(b?.adGenre);
      switch ([isExtTypeSupportedA, isExtTypeSupportedB].join()) {
        case 'true,false':
          return 1;
        case 'false,true':
          return -1;
        default:
          return 0;
      }
    });
  }

  getSponsorGenre() {
    const adServerExtension = this.extensions.find(
      (extension) =>
        VASTAd.supportedExtensionTypes.includes(extension.type) &&
        !!extension.adGenre,
    );

    if (!adServerExtension) {
      return undefined;
    }
    const { adGenre } = adServerExtension;
    return VASTAd.sponsorGenre.includes(adGenre) ? adGenre : undefined;
  }

  setNextWrapperURL(vastAdTagURI) {
    this.nextWrapperURL = vastAdTagURI;
  }

  setErrorURLTemplates(urls) {
    this.errorURLTemplates = this.errorURLTemplates.concat(urls);
  }

  setImpressionURLTemplates(urls) {
    this.impressionURLTemplates = this.impressionURLTemplates.concat(urls);
  }

  setCreatives(creatives) {
    Array.prototype.push.apply(this.creatives, creatives);
  }

  setExtensions(extensions) {
    Array.prototype.push.apply(this.extensions, extensions);
  }

  parse(xmlElement) {
    this.id = xmlElement.id;

    const subElements = xmlElement.childNodes;
    for (let i = 0; i < subElements.length; i += 1) {
      const child = subElements[i];
      switch (child.nodeName) {
        case 'Error': {
          const errorURLTemplate = [VASTAd.parseErrorElement(child)];
          this.setErrorURLTemplates(errorURLTemplate);
          break;
        }

        // Only for Wrapper element
        case 'VASTAdTagURI': {
          this.setNextWrapperURL(Utils.parseNodeText(child));
          break;
        }

        case 'Impression': {
          const impressionURLTemplate = [VASTAd.parseImpressionElement(child)];
          this.setImpressionURLTemplates(impressionURLTemplate);
          break;
        }

        case 'Creatives': {
          this.setCreatives(VASTAd.parseCreativesElement(child));
          break;
        }

        case 'Extensions': {
          this.setExtensions(VASTAd.parseExtensionsElement(child));
          break;
        }

        default: {
          if (child.nodeName !== '#text') {
            logger.warn(
              `OnePlayer Ads > ${child.nodeName} is not implemented (Wrapper/InLine)`,
            );
          }
        }
      }
    }
  }
}
