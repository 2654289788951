import { createSelector } from 'reselect';
import { skipContentOffsets } from '@canalplus/oneplayer-constants';
import { milliSecondsToSeconds } from '@canalplus/oneplayer-utils';
import { skipContentSelector } from './configSelector';
import { currentTimeSelector, videoDurationSelector } from './coreSelector';
import { getLocatorsForSkip } from './dataSelector';
var countDownBeforeSkipOpeningCreditHidden = skipContentOffsets.countDownBeforeSkipOpeningCreditHidden, countDownBeforeSkipEndCredits = skipContentOffsets.countDownBeforeSkipEndCredits, countDownBeforeSkipRecapHidden = skipContentOffsets.countDownBeforeSkipRecapHidden;
/**
 *
 * @param {locator} FTXC|LTXC|FFTC|LFTC
 * @returns {Locator} Return the locator rounded or null
 */
function getSafeLocator(locators, locator) {
    if (locators === undefined) {
        return null;
    }
    var givenLocator = locators[locator];
    return typeof givenLocator === 'number' && !Number.isNaN(givenLocator)
        ? milliSecondsToSeconds(givenLocator)
        : null;
}
/**
 * @param {number} endCreditPosition (Value received by Hapi)
 * @param {number} intervalTimeToShowCardEndCredits (Hard coded value received from config)
 * @param {number} currentTime (Dynamic value that correspond to the current time in the program)
 * @param {number} duration (duration of the program)
 * @returns {Number}
 */
export function getRemainingTimeBeforeUnskippable(positionLocator, intervalTimeToShowCard, currentTime, duration) {
    if (duration - positionLocator < intervalTimeToShowCard) {
        return Math.round(duration - currentTime);
    }
    return positionLocator + intervalTimeToShowCard - Math.round(currentTime);
}
/**
 * @returns {function(object): Number}
 */
export var getShowSkippableTimeOffsetSelector = createSelector(skipContentSelector, function (skipContent) { return skipContent.showSkippableTimeOffset; });
/**
 * @returns {function(object): Number}
 */
export var getCountDownBeforeSkipEndCreditsSelector = createSelector(skipContentSelector, function (skipContent) {
    return (skipContent === null || skipContent === void 0 ? void 0 : skipContent.countDownBeforeSkipEndCredits) || countDownBeforeSkipEndCredits;
});
/**
 * @returns {function(object): Number}
 */
export var getCountDownBeforeSkipGenericSelector = createSelector(skipContentSelector, function (skipContent) {
    return (skipContent === null || skipContent === void 0 ? void 0 : skipContent.countDownBeforeSkipOpeningCreditHidden) ||
        countDownBeforeSkipOpeningCreditHidden;
});
/**
 * @returns {function(object): Number}
 */
export var getCountDownBeforeSkipPreviouslySelector = createSelector(skipContentSelector, function (skipContent) {
    return (skipContent === null || skipContent === void 0 ? void 0 : skipContent.countDownBeforeSkipRecapHidden) ||
        countDownBeforeSkipRecapHidden;
});
/**
 * @returns {function(object): string}
 */
export var endCreditPositionSelector = createSelector(getLocatorsForSkip, getShowSkippableTimeOffsetSelector, videoDurationSelector, function (locators, showSkippableTimeOffset, duration) {
    return (locators === null || locators === void 0 ? void 0 : locators.FFEC)
        ? milliSecondsToSeconds(locators.FFEC)
        : Math.round(duration - showSkippableTimeOffset);
});
/**
 * @returns {function(object): Object || 0}
 */
export var getDataForSkipPreviously = createSelector(getLocatorsForSkip, function (locators) { return ({
    FTXC: getSafeLocator(locators, 'FTXC'),
    LTXC: getSafeLocator(locators, 'LTXC'),
}); });
/**
 * @returns {function(object): Object || 0}
 */
export var getDataForSkipGeneric = createSelector(getLocatorsForSkip, function (locators) { return ({
    FFTC: getSafeLocator(locators, 'FFTC'),
    LFTC: getSafeLocator(locators, 'LFTC'),
}); });
/**
 * @returns {function(object): Object || 0}
 */
export var getRemainingTimeBeforeUnskippableSelector = createSelector(endCreditPositionSelector, getCountDownBeforeSkipEndCreditsSelector, currentTimeSelector, videoDurationSelector, function (endCreditPosition, intervalTimeToShowCardEndCredits, currentTime, duration) {
    var timecode = typeof currentTime === 'number' ? currentTime : currentTime.getTime();
    return getRemainingTimeBeforeUnskippable(endCreditPosition, intervalTimeToShowCardEndCredits, Math.round(timecode), duration);
});
