import { logger } from '@canalplus/oneplayer-utils';
import { getInstance } from '../../utils/instance';
export default function triggerApi(uid, name, data) {
    var _a, _b;
    if (data === void 0) { data = null; }
    var instance = getInstance(uid);
    // check if instance has not been destroy
    if (instance) {
        var events = instance.config.events || instance.initialConfig.events;
        var fn_1 = events && events[name];
        if (fn_1) {
            logger.log("One Player Events > Call the callback event for event '".concat(name, "'"), ((_b = (_a = fn_1 === null || fn_1 === void 0 ? void 0 : fn_1.prototype) === null || _a === void 0 ? void 0 : _a.constructor) === null || _b === void 0 ? void 0 : _b.name) || '');
            switch (name) {
                case 'onExitButtonClicked':
                    /**
                     * The need of setTimeout here is due to a known React issue
                     * — "Issue is related to mutating nodes synchronously within an event loop.
                     *  We may want to make unmount take effect at the end of the event loop.
                     *  I think this demonstrates the more general problem of how to handle these
                     *  top-level functions from within an event loop." (https://github.com/facebook/react/issues/3298#issuecomment-76825803)
                     */
                    setTimeout(function () {
                        fn_1({ data: data }, instance);
                    }, 0);
                    break;
                default:
                    fn_1({ data: data }, instance);
                    break;
            }
        }
    }
}
