/**
 * From string to js bytes array
 * @param str some string to be converted
 * @returns the resulting Uint8Array
 */
export function strToBytes(str: string): Uint8Array {
  const len = str.length;
  const arr = new Uint8Array(len);
  for (let i = 0; i < len; i += 1) {
    /* eslint-disable-next-line no-bitwise */
    arr[i] = str.charCodeAt(i) & 0xff;
  }
  return arr;
}

/**
 * From js bytes array to string
 * @param bytes some Uint8Array to be converted
 * @returns the resulting string
 */
export function bytesToStr(bytes: Uint8Array): string {
  // NOTE: ugly I know, but TS is problematic here (you can try)
  return String.fromCharCode.apply(null, bytes as unknown as number[]);
}

/**
 * From js bytes array to string UTF 16
 * @param bytes some Uint8Array to be converted
 * @returns the resulting string
 */
export function bytesToUTF16Str(bytes: Uint8Array): string {
  let str = '';
  const len = bytes.length;
  for (let i = 0; i < len; i += 2) {
    str += String.fromCharCode(bytes[i]);
  }
  return str;
}
