/* eslint-disable max-classes-per-file */
// TODO: split classes into files and remove eslint-disable
import * as Utils from './utils';
import VASTCompanionAd from './VASTCompanionAd';
import VASTMediaFile from './VASTMediaFile';

class VASTCreative {
  constructor() {
    this.trackingEvents = [];
  }

  setTrackingEvents(trackingEvents) {
    this.trackingEvents = this.trackingEvents.concat(trackingEvents);
  }
}

export class VASTCreativeLinear extends VASTCreative {
  static parseURLElements(urlElements) {
    const URLTemplates = [];

    urlElements.forEach((urlElement) => {
      URLTemplates.push(Utils.parseNodeText(urlElement));
    });

    return URLTemplates;
  }

  constructor(xmlElement) {
    super();

    this.type = 'linear';
    this.duration = 0;
    this.skipDelay = null;
    this.mediaFiles = [];
    this.videoClickThroughURLTemplate = null;
    this.videoClickTrackingURLTemplates = [];
    this.videoCustomClickURLTemplates = [];
    this.adParameters = null;

    this.parse(xmlElement);
  }

  hasMediaFilesSupported(mimeTypes) {
    const mediaFilesSupported = this.getMediaFilesSupported(mimeTypes);

    if (mediaFilesSupported.length > 0) {
      return true;
    }

    return false;
  }

  getMediaFilesSupported(mimeTypes) {
    const mediaFilesSupported = [];

    mimeTypes.forEach((mimeType) => {
      const mediaFiles = this.mediaFiles.filter(
        (mediaFile) => mediaFile.mimeType === mimeType,
      );

      if (mediaFiles.length > 0) {
        Array.prototype.push.apply(mediaFilesSupported, mediaFiles);
      }
    });

    return mediaFilesSupported;
  }

  setDuration(durationElement) {
    this.duration = Utils.parseDuration(Utils.parseNodeText(durationElement));
  }

  setSkipDelay(skipOffset) {
    if (!skipOffset) {
      this.skipDelay = null;
    } else if (skipOffset.charAt(skipOffset.length - 1) === '%') {
      const percent = parseInt(skipOffset, 10);
      this.skipDelay = this.duration * (percent / 100);
    } else {
      this.skipDelay = Utils.parseDuration(skipOffset);
    }
  }

  setVideoClickThroughURLTemplate(clickThroughElement) {
    this.videoClickThroughURLTemplate =
      Utils.parseNodeText(clickThroughElement);
  }

  setVideoClickTrackingURLTemplates(urls) {
    this.videoClickTrackingURLTemplates =
      this.videoClickTrackingURLTemplates.concat(urls);
  }

  setVideoCustomClickURLTemplates(urls) {
    this.videoClickTrackingURLTemplates =
      this.videoClickTrackingURLTemplates.concat(urls);
  }

  setAdParameters(adParamsElement) {
    if (adParamsElement) {
      this.adParameters = Utils.parseNodeText(adParamsElement);
    }
  }

  setMediaFiles(mediaFilesElement) {
    if (mediaFilesElement) {
      const mediaFileElements = Utils.childrenByName(
        mediaFilesElement,
        'MediaFile',
      );
      mediaFileElements.forEach((mediaFileElement) => {
        this.mediaFiles.push(new VASTMediaFile(mediaFileElement));
      });
    }
  }

  parse(creativeElement) {
    this.setDuration(Utils.childByName(creativeElement, 'Duration'));

    // WTF ?
    const originNodeName =
      creativeElement.parentNode.parentNode.parentNode.nodeName;
    if (this.duration === -1 && originNodeName !== 'Wrapper') {
      return;
    }

    // skipOffset is a time value that identifies when skip controls
    // should be available to the end user
    this.setSkipDelay(creativeElement.getAttribute('skipOffset'));

    const videoClicksElement = Utils.childByName(
      creativeElement,
      'VideoClicks',
    );

    if (videoClicksElement != null) {
      const clickThroughElement = Utils.childByName(
        videoClicksElement,
        'ClickThrough',
      );
      this.setVideoClickThroughURLTemplate(clickThroughElement);

      const clickTrackingElements = Utils.childrenByName(
        videoClicksElement,
        'ClickTracking',
      );
      const clickTrackingURL = VASTCreativeLinear.parseURLElements(
        clickTrackingElements,
      );
      this.setVideoClickTrackingURLTemplates(clickTrackingURL);

      const customClickElements = Utils.childrenByName(
        videoClicksElement,
        'CustomClick',
      );
      const customClickURL =
        VASTCreativeLinear.parseURLElements(customClickElements);
      this.setVideoCustomClickURLTemplates(customClickURL);
    }

    this.setAdParameters(Utils.childByName(creativeElement, 'AdParameters'));

    const trackingEventsElement = Utils.childByName(
      creativeElement,
      'TrackingEvents',
    );
    const trackingEvents = Utils.parseTrackingEventsElement(
      trackingEventsElement,
    );
    this.setTrackingEvents(trackingEvents);

    this.setMediaFiles(Utils.childByName(creativeElement, 'MediaFiles'));
  }
}

export class VASTCreativeNonLinear extends VASTCreative {
  constructor(xmlElement) {
    super();

    this.type = 'nonlinear';
    this.variations = [];

    VASTCreativeNonLinear.parse(xmlElement);
  }

  static parse(creativeElement) {
    // TODO
    return creativeElement;
  }
}

export class VASTCreativeCompanion extends VASTCreative {
  constructor(xmlElement) {
    super();

    this.type = 'companion';
    this.variations = [];
    delete this.trackingEvents;

    this.parse(xmlElement);
  }

  setVariations(companionElements) {
    companionElements.forEach((companionElement) => {
      this.variations.push(new VASTCompanionAd(companionElement));
    });
  }

  parse(creativeElement) {
    const companionElements = Utils.childrenByName(
      creativeElement,
      'Companion',
    );
    this.setVariations(companionElements);
  }
}
