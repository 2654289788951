import { createContext } from 'react';

import createConsumer from './createConsumer';
import createProvider from './createProvider';
import getPhrases from './getPhrases';

const createI18n = () => {
  const store = {};
  const { Provider, Consumer } = createContext(store);
  const provider = createProvider(Provider);
  const consumer = createConsumer(Consumer);
  const phrases = getPhrases;

  return {
    provider,
    consumer,
    phrases,
  };
};

const { provider, consumer, phrases } = createI18n();

export default { provider, consumer };
export {
  consumer as i18nConsumer,
  phrases as i18nLanguage,
  provider as i18nProvider,
};
