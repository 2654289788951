// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Component
 */

.kr9lNhO9visYtBMLTBtC {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 0;
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
}

.kr9lNhO9visYtBMLTBtC[disabled] {
    pointer-events: none;
    background: none;
    opacity: 0.3;
  }

.kr9lNhO9visYtBMLTBtC.rBDo8zxJs2N2TwxQwifg {
    cursor: default;
    opacity: 0.3;
  }

/* TODO: This pseudo-selector is not yet fully implemented : https://caniuse.com/#feat=css-focus-visible
   * But we don't want the mouse focus to add an outline
   * So, for now, there is no visual feedback when focusing via keyboard (this is bad for a11y)
   * Either use polyfill : https://css-tricks.com/keyboard-only-focus-styles/
   * Or wait for it to be supported on major browsers : https://www.chromestatus.com/feature/5823526732824576
  */

.kr9lNhO9visYtBMLTBtC.rmpi2vSnaj1280o9GXcG {
    box-shadow: inset 0 0 0 2px rgba(27, 127, 204, 0.8);
  }

.kr9lNhO9visYtBMLTBtC.rmpi2vSnaj1280o9GXcG {
    box-shadow: inset 0 0 0 2px rgba(27, 127, 204, 0.8);
  }

.kr9lNhO9visYtBMLTBtC:focus-visible {
    box-shadow: inset 0 0 0 2px rgba(27, 127, 204, 0.8);
  }
`, "",{"version":3,"sources":["webpack://./src/ui/components/presentational/button/Button.css"],"names":[],"mappings":"AAAA;;EAEE;;AAEF;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,UAAU;EACV,eAAe;EACf,aAAa;EACb,gBAAgB;EAChB,YAAY;AAsBd;;AApBE;IACE,oBAAoB;IACpB,gBAAgB;IAChB,YAAY;EACd;;AAEA;IACE,eAAe;IACf,YAAY;EACd;;AAEA;;;;;GAKC;;AACD;IACE,mDAAiD;EACnD;;AAFA;IACE,mDAAiD;EACnD;;AAFA;IACE,mDAAiD;EACnD","sourcesContent":["/**\n * Component\n */\n\n.button {\n  display: inline-block;\n  width: 100%;\n  height: 100%;\n  padding: 0;\n  cursor: pointer;\n  outline: none;\n  background: none;\n  border: none;\n\n  &[disabled] {\n    pointer-events: none;\n    background: none;\n    opacity: 0.3;\n  }\n\n  &.disabled {\n    cursor: default;\n    opacity: 0.3;\n  }\n\n  /* TODO: This pseudo-selector is not yet fully implemented : https://caniuse.com/#feat=css-focus-visible\n   * But we don't want the mouse focus to add an outline\n   * So, for now, there is no visual feedback when focusing via keyboard (this is bad for a11y)\n   * Either use polyfill : https://css-tricks.com/keyboard-only-focus-styles/\n   * Or wait for it to be supported on major browsers : https://www.chromestatus.com/feature/5823526732824576\n  */\n  &:focus-visible {\n    box-shadow: inset 0 0 0 2px rgb(27 127 204 / 80%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `kr9lNhO9visYtBMLTBtC`,
	"disabled": `rBDo8zxJs2N2TwxQwifg`,
	"focus-visible": `rmpi2vSnaj1280o9GXcG`
};
module.exports = ___CSS_LOADER_EXPORT___;
