import axios from 'axios';

import { ErrorCodes } from '@canalplus/oneplayer-constants';
import { isObject, logger } from '@canalplus/oneplayer-utils';

import Parser from './Parser';

const { AD_BLOCKER_DETECTED, NO_PARSABLE_ADS, NO_ADS_SUPPORTED } = ErrorCodes;

export default class Manager {
  constructor(mimeTypesSupported) {
    this.parser = new Parser();
    this.mimeTypesSupported = mimeTypesSupported;
  }

  async requestAdServer(url) {
    try {
      logger.debug('OnePlayer Ads Manager > requestAdServer', url);
      if (typeof url !== 'string') {
        throw NO_ADS_SUPPORTED;
      }
      const playlists = [];

      const { data: request } = await axios.get(url, {
        responseType: 'document',
        withCredentials: true, // indicates whether or not cross-site Access-Control requests should be made using credentials like cookies or authorization headers, needed for ads
      });

      // Can be 'vmap:VMAP' or 'VAST'
      const responseType = request?.documentElement.nodeName;

      let response;
      // We need to call the appropriate parser
      // method depending on the responseType
      if (responseType === 'VAST') {
        response = await this.parser.parseVast(request);
      } else if (responseType === 'vmap:VMAP') {
        response = await this.parser.parseVmap(request);
      } else {
        throw NO_PARSABLE_ADS;
      }

      logger.debug(
        'OnePlayer Ads Manager > requestAdServer response',
        response,
      );

      for (let r = 0, len = response.length; r < len; r += 1) {
        const ads = response[r].ad.getAdsSupported(this.mimeTypesSupported);
        const duration = response[r].ad.getSupportedDuration();

        // we make sure the sponsors are played last
        for (let i = 0; i < ads.length; i += 1) {
          ads[i].sortBySponsoring();
        }

        // An adType will be specified if the ads
        // come from a VMAP
        const adType = response[r].adType || null;

        // we get the ad start time when we parse the vmap
        // This might not work if we go back to using vast directly
        // instead of vmaps
        const { adStartTime } = response[r];

        // Get trackingEvents from VMAP
        const trackingEvents = adType
          ? this.parser.getTrackingEvents(adType, adStartTime)
          : [];
        playlists.push({
          playlist: ads,
          duration,
          adType,
          trackingEvents,
          adStartTime,
        });
      }

      if (!playlists.length) {
        throw NO_ADS_SUPPORTED;
      }
      // Return only supported ads
      return playlists;
    } catch (error) {
      logger.error(
        'OnePlayer Ads Manager > requestAdServer response with an error',
        error,
      );

      // Only way to detect if the ad server request have been blocked by an Ad Blocker
      if (isObject(error) && !error.status) {
        throw AD_BLOCKER_DETECTED;
      }

      throw error;
    }
  }
}
