import { jsx as _jsx } from "react/jsx-runtime";
import { i18nConsumer } from '../../../../utils/i18n/i18n';
import withVariant from '../../../hoc/withVariant';
import { CROSS_GLYPH } from '../../../constants/Glyphs';
import IconButton from '../iconbutton/IconButton';
var styles = require('./ExitButton.css');
var ExitButton = function (_a) {
    var t = _a.t, onClick = _a.onClick, variant = _a.variant;
    return (_jsx("div", { className: styles.exitButtonWrapper, children: _jsx(IconButton, { ariaLabel: t('ariaLabels.Close'), customStyle: styles["exitButton--".concat(variant)], onClick: onClick, glyph: CROSS_GLYPH, width: "10", height: "10" }) }));
};
export default i18nConsumer(withVariant(ExitButton));
