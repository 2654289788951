/**
 * kind of a polyfill for matchAll, useful because it's not supported on chromecast V1
 * @param str string to match
 * @param regexp the regexp to use for the match
 * @returns of match (with all groups, so you need to flatmap this to get only the group you want)
 */
function matchAll(str: string, regexp: RegExp): RegExpExecArray[] {
  let match: RegExpExecArray | null | undefined;
  const result: RegExpExecArray[] = [];

  do {
    match = regexp.exec(str);
    if (match) {
      result.push(match);
    }
  } while (match !== null);

  return result;
}

export default matchAll;
