import flatMap from './lodash/flatMap';
import matchAll from './string/matchAll';

/**
 * Return the micro eligibility associated to a Macro
 * as a list of string
 * @param macro macros
 * @param microEligibility micro eligibility
 * @returns extract macro eligibilities
 */
export function getMicros(macro: string, microEligibility: string): string[] {
  const reg = new RegExp(`\\b${macro}:\\[(.*?)\\]`);
  const res = reg.exec(microEligibility);
  if (res !== null) {
    return res[1].split(',');
  }
  return [];
}

/**
 * Returns the list of all the micro associated to a list
 * of macro.
 * @export
 * @param microEligibility micro eligibility
 * @param macros macros
 * @returns todo...
 */
export function extractOffers(
  microEligibility: string,
  macros?: string[],
): string[] {
  if (!microEligibility) {
    return [];
  }

  let offers: string[] = [];
  if (macros) {
    macros.forEach((macro) => {
      offers = offers.concat(getMicros(macro, microEligibility));
    });
  } else {
    offers = flatMap(
      matchAll(microEligibility, /\[(.*?)\]+/g),
      (capture: string[]) => capture[1].split(','),
    );
  }
  return offers.filter((name) => name !== undefined && name !== '').sort();
}

/**
 * Returns true if the user is logged in
 * @export
 * @param passInfo user infos
 * @param passInfo.passToken pass token
 * @returns is user logged
 */
export function isUserLogged({ passToken }: { passToken: string }): boolean {
  return passToken?.[0] !== '0';
}

/**
 * Returns true if the user is subscribed
 * @export
 * @param passInfo user infos
 * @param passInfo.passToken pass token
 * @param passInfo.microEligibility micro eligibility
 * @returns is user subscribed
 */
export function isUserSubscriber({
  passToken,
  microEligibility,
}: {
  passToken: string;
  microEligibility: string;
}): boolean {
  return (
    isUserLogged({ passToken }) &&
    extractOffers(microEligibility, ['OFFER']).includes('O_ABO')
  );
}

/**
 * Returns the login status of a user
 * @export
 * @param passInfo user infos
 * @param passInfo.passToken pass token
 * @param passInfo.microEligibility micro eligibility
 * @returns 'non_logged' | 'non_subscriber' | 'subscriber'
 */
export function getUserLoginStatus({
  passToken,
  microEligibility,
}: {
  passToken: string;
  microEligibility: string;
}): 'non_logged' | 'non_subscriber' | 'subscriber' {
  const nonLoggedStatus = 'non_logged';
  const nonSubscriberStatus = 'non_subscriber';
  const subscriberStatus = 'subscriber';
  const isLogged = isUserLogged({ passToken });

  if (!isLogged) {
    return nonLoggedStatus;
  }

  const isSubscriber = isUserSubscriber({ passToken, microEligibility });

  return isSubscriber ? subscriberStatus : nonSubscriberStatus;
}
