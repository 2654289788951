import { isObject } from './object';

/**
 * Make a deep extend of target thanks to arguments (one or many)
 * @param src source object
 * @param objects extensions
 * @returns extended object
 */
export function deepExtend(
  src: { [key: string]: any },
  ...objects: any[]
): { [key: string]: any } {
  const target = { ...src };

  objects.forEach((object) => {
    Object.keys(object).forEach((prop) => {
      if (
        isObject(object[prop]) &&
        object[prop]?.getTime === undefined &&
        !Array.isArray(object[prop]) &&
        object[prop] !== null &&
        typeof object[prop] !== 'function'
      ) {
        target[prop] =
          undefined === target[prop]
            ? deepExtend({}, object[prop])
            : deepExtend(target[prop], object[prop]);
      } else {
        target[prop] = object[prop];
      }
    });
  });

  return target;
}

/**
 *
 * @param original source class
 * @returns copied class instance
 */
export function copyClassInstance(original: unknown): any {
  const copied = Object.assign(
    Object.create(Object.getPrototypeOf(original)),
    original,
  );

  return copied;
}

/**
 * Get the value between two bounds (min, max)
 * E.g : minMax(1, 0, 10) -> 1
 *       minMax(24, 0, 10) -> 10
 *       minMax(-1, 0, 10) -> 0
 * @param value value to be tested
 * @param min min bound
 * @param max max bound
 * @returns min, max or value
 */
export function minMax(value: number, min: number, max: number): number {
  if (value <= min) {
    return min;
  }
  if (value >= max) {
    return max;
  }

  return value;
}

export const isSecure = (($window: any): boolean | null =>
  $window ? /https/.test($window.location.protocol) : null)(
  (global as any).window,
);
