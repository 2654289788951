import {
  getUserLoginStatus,
  template,
  toMilliseconds,
} from '@canalplus/oneplayer-utils';

import {
  MIDROLL_AD_TYPE,
  POSTROLL_AD_TYPE,
  PREROLL_AD_TYPE,
} from './constants';

export function parseNodeText(node) {
  return node && (node.textContent || node.text || '').trim();
}

export function childByName(node, name) {
  const ref = node.childNodes;
  for (let i = 0; i < ref.length; i += 1) {
    const child = ref[i];
    if (child.nodeName === name) {
      return child;
    }
  }

  return null;
}

export function childrenByName(node, name) {
  const childs = [];

  const ref = node.childNodes;
  for (let i = 0; i < ref.length; i += 1) {
    const child = ref[i];
    if (child.nodeName === name) {
      childs.push(child);
    }
  }

  return childs;
}

function resolveURLTemplates(urls, variables = {}) {
  const v = variables;
  const resolvedUrls = [];

  if (!('CACHEBUSTING' in variables)) {
    v.CACHEBUSTING = Math.round(Math.random() * 1.0e10);
  }

  urls.forEach((url) => {
    let resolveURL = url;

    Object.keys(v).forEach((key) => {
      if (v[key]) {
        const value = v[key];
        const macro1 = `[${key}]`;
        const macro2 = `%%${key}%%`;

        resolveURL = resolveURL.replace(macro1, value);
        resolveURL = resolveURL.replace(macro2, value);
      }
    });

    resolvedUrls.push(resolveURL);
  });

  return resolvedUrls;
}

export function track(urls, variables = {}) {
  let resolvedURLs = urls;
  if (Object.keys(variables).length > 0) {
    resolvedURLs = resolveURLTemplates(urls, variables);
  }

  resolvedURLs.forEach((url) => {
    if (window != null) {
      const image = new Image();
      image.src = url;
    }
  });
}

export function trackErrorWithCode(urls, errorCode) {
  track(urls, {
    ERRORCODE: errorCode,
  });
}

export function parseDuration(durationString) {
  if (durationString == null) {
    return -1;
  }

  const durationComponents = durationString.split(':');
  if (durationComponents.length !== 3) {
    return -1;
  }

  const secondsAndMS = durationComponents[2].split('.');
  let seconds = parseInt(secondsAndMS[0], 10);
  if (secondsAndMS.length === 2) {
    seconds += parseFloat(`0.${secondsAndMS[1]}`);
  }
  const minutes = parseInt(durationComponents[1] * 60, 10);
  const hours = parseInt(durationComponents[0] * 60 * 60, 10);
  if (
    Number.isNaN(
      hours ||
        Number.isNaN(
          minutes || Number.isNaN(seconds || minutes > 60 * 60 || seconds > 60),
        ),
    )
  ) {
    return -1;
  }

  return hours + minutes + seconds;
}

export function parseTrackingEventsElement(trackingEventsElement) {
  const trackingEvents = [];

  if (trackingEventsElement) {
    const trackingElements = childrenByName(trackingEventsElement, 'Tracking');

    trackingElements.forEach((trackingElement) => {
      let eventName = trackingElement.getAttribute('event');
      const trackingURLTemplate = parseNodeText(trackingElement);

      if (eventName !== null && trackingURLTemplate !== null) {
        if (eventName === 'progress') {
          const offset = trackingElement.getAttribute('offset');
          if (!offset) {
            return;
          }
          if (offset.charAt(offset.length - 1) === '%') {
            eventName = `progress-${offset}`;
          } else {
            eventName = `progress-${Math.round(parseDuration(offset))}`;
          }
        }

        trackingEvents.push({ eventName, url: trackingURLTemplate });
      }
    });
  }

  return trackingEvents;
}

/**
 * Create a string of url query parameters from an object
 * @param  {Object.<string, string>} obj - contains the param keys / values
 * @return {string} query parameter
 */
export const joinParams = (obj) =>
  Object.keys(obj)
    .sort()
    .map((key) => {
      const value = obj[key];

      if (
        value === null ||
        value === 'null' ||
        value === undefined ||
        value === 'undefined'
      ) {
        return '';
      }

      return `${key}=${value}`;
    })
    .filter((x) => x.length > 0)
    .join('&');

/**
 * Stringifies and adds a list of parameters to a url
 * @param  {string} base - url (domain name and path)
 * @param  {Object.<string, string>} params - url param separated by '&'
 * @param  {Object.<string, string>} extraParameters - Other params separated by ';' from the others
 * @param  {array of objects.<string, string>} extraMidrollParamsList - midroll params
 * @return {string} url
 */
export function stringifyUrl(
  base,
  params,
  extraParams,
  extraMidrollParamsList,
) {
  const query = joinParams(params);

  const extraQuery = extraParams ? joinParams(extraParams) : null;
  const extraMidrollQuery = extraMidrollParamsList
    ? extraMidrollParamsList
        .map((midrollParams) => joinParams(midrollParams))
        .join(';')
    : null;

  let finalQuery = query;
  if (extraQuery) {
    finalQuery += `;${extraQuery}`;
  }
  if (extraMidrollQuery) {
    finalQuery += `;${extraMidrollQuery}`;
  }

  if (base.indexOf('?') >= 0) {
    return `${base}${finalQuery}`;
  }

  return `${base}?${finalQuery}`;
}

/**
 * takes string time offset '(HH):MM:SS(.ms)' and returns it in seconds
 * @export
 * @param {string} timeoffset - '(HH):MM:SS(.ms)'
 * @returns {float} seconds
 */
export function parseTimeOffset(timeoffset) {
  // we want to match this kinds of time input: 09:02:31.222, 02:31.222, 02:31
  const regex =
    /^([0-9][0-9])?:?([0-5][0-9]):([0-5][0-9]).?([0-9]?[0-9]?[0-9]?)$/;
  const result = timeoffset.value.match(regex);

  if (!result) {
    return null;
  }

  const [hours = 0, minutes = 0, seconds = 0, milliseconds = 0] = result.splice(
    1,
    5,
  );
  return +hours * 3600 + +minutes * 60 + +seconds + +milliseconds * 0.001;
}

/**
 * returns adType and optional adStartTime from ad timeOffset
 * @export
 * @param {string} timeOffset - '(HH):MM:SS(.ms)'
 * @returns {Object.<adType: string, adStartTime: float>}
 */
export function getAdInfoFromTimeOffset(timeOffset) {
  if (timeOffset.value === 'start') {
    return { adType: PREROLL_AD_TYPE, adStartTime: 0 };
  }
  if (timeOffset.value === 'end') {
    return { adType: POSTROLL_AD_TYPE, adStartTime: Infinity };
  }

  // case of midroll ads
  const adStartTime = parseTimeOffset(timeOffset);
  return adStartTime ? { adType: MIDROLL_AD_TYPE, adStartTime } : {};
}
/**
 * Get the child nodes of an xml element
 * without the text nodes
 * @export
 * @param {XmlElement} parentNode
 * @returns {Array<XmlElement>}
 */
export function getChildNodesWithoutTexts(parentNode) {
  const { childNodes } = parentNode;
  const filteredChildNodes = [];
  for (let i = 0; i < childNodes.length; i += 1) {
    const node = childNodes[i];
    if (node.nodeName !== '#text') {
      filteredChildNodes.push(node);
    }
  }

  return filteredChildNodes;
}

/**
 * Returns the closest start time in a list given a current time
 * @export
 * @param {float} currentTime
 * @param {Array<float>} startTimes
 * @returns {float} closestStartTime
 */
export function getClosestStartTime(currentTime, startTimes) {
  if (!startTimes || !startTimes.length) {
    return null;
  }

  return startTimes.reduce((closestStartTime, startTime) => {
    if (
      !closestStartTime ||
      Math.abs(startTime - currentTime) <
        Math.abs(closestStartTime - currentTime)
    ) {
      return startTime;
    }
    return closestStartTime;
  });
}
/**
 * Return the closest start time given midroll ad routines
 * @export
 * @param {float} currentTime
 * @param {Array<object>} adRoutines
 * @returns {float} closest start time
 */
export function getClosestMidrollRoutineStartTime(currentTime, adRoutines) {
  if (!currentTime || !adRoutines) {
    return null;
  }

  return getClosestStartTime(
    currentTime,
    adRoutines.map((r) => r.adStartTime),
  );
}

/**
 * Returns all the common ad data that can be used by adservers
 * @export
 * @param {object} userConfig // user config state
 * @param {object} dataConfig // data config state
 * @param {object} paramsConfig // params config state
 * @param {boolean} jsAdsSupport // do we support js ads ?
 * @returns {object} common ad params
 */
export function getCommonAdDatas(
  userConfig,
  dataConfig,
  paramsConfig,
  jsAdsSupport,
) {
  const { passToken, microEligibility, trackingKeyId, deviceKeyId } =
    userConfig;
  const {
    id,
    adChannelEpgId,
    adCategory,
    duration: videoDuration,
    midrollMarkers,
    hash,
    providerId,
  } = dataConfig;
  const { offerZone } = paramsConfig;

  return {
    adChannelEpgId,
    providerId,
    trackingKeyId,
    deviceKeyId,
    offerZone,
    contentId: id,
    hash,
    canDisplayVpaids: jsAdsSupport,
    videoDuration: videoDuration || 300,
    adCategory: encodeURIComponent(adCategory),
    pageRandomValue: Math.floor(toMilliseconds(Math.random()) + 1),
    videoInstanceRandomValue: Math.floor(toMilliseconds(Math.random()) + 1),
    userLoginStatus: getUserLoginStatus({ passToken, microEligibility }),
    // convert markers from seconds to milliseconds
    midrollMarker: midrollMarkers
      ? midrollMarkers.map((n) => toMilliseconds(n))
      : midrollMarkers,
  };
}

/**
 * Returns the same ad object parameters
 * but with templated values updated with the corresponding values
 * @export
 * @param {object} adParams // the ad parameter to be used later in the ad call
 * @param {object} dataForTemplate // the data to use to fill the templated value
 * @returns {object} // ad params with correct values
 */
export function fillTemplatedAdParameter(adParams, dataForTemplate) {
  if (!adParams) {
    return {};
  }

  return Object.keys(adParams).reduce(
    (allParams, param) => ({
      ...allParams,
      [param]: adParams[param]
        ? template(adParams[param], dataForTemplate)
        : '',
    }),
    {},
  );
}

export function mergeAdServerUrlParams(initalParams, customParams) {
  if (customParams instanceof Object) {
    // transform an object into a list of key-value pairs
    // => keep only key-value pairs with value of type string || int
    const filteredParams = Object.keys(customParams).reduce((acc, key) => {
      if (
        typeof customParams[key] === 'string' ||
        typeof customParams[key] === 'number'
      ) {
        acc[key] = customParams[key];
        return acc;
      }
      return acc;
    }, {});
    return {
      ...initalParams,
      // transforms a list of key-value pairs into an object
      ...filteredParams,
    };
  }
  return initalParams;
}
