var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { LanguageTypes } from '@canalplus/oneplayer-constants';
import InitError from '../ui/components/presentational/initerror/InitError';
import { i18nProvider } from './i18n/i18n';
/**
 * An utils InitErrorManager class that manage and display error before
 * the full player context is instanciate/mounted.
 *
 * /!\ At that instant, Redux is not yet available.
 */
var InitErrorManager = /** @class */ (function () {
    function InitErrorManager(_a) {
        var root = _a.root, uid = _a.uid, exitButton = _a.exitButton, isTvPlayer = _a.isTvPlayer;
        this.root = root;
        this.uid = uid;
        this.exitButton = exitButton;
        this.i18nProviderConfig = {
            locale: LanguageTypes.FR_FR,
            phrases: {},
            update: function () { },
        };
        this.isTvPlayer = isTvPlayer;
    }
    /**
     * Set the i18n config needed in order to perform translation in the UI.
     * @param i18nProviderConfig config for i18n provider
     */
    InitErrorManager.prototype.setI18nConfig = function (i18nProviderConfig) {
        this.i18nProviderConfig = __assign(__assign({}, i18nProviderConfig), { update: function () { } });
    };
    /**
     * Perform the mechanism to display an error inside the player container,
     * before the full redux context is mounted.
     *
     * /!\ That part is also i18n compliant.
     * /!\ That method throw!
     * @param ITriggeringOptions object containing the translation key
     * @param ITriggeringOptions.i18nKey translation key
     */
    InitErrorManager.prototype.trigger = function (_a) {
        var i18nKey = _a.i18nKey;
        if (!this.root) {
            throw new Error('config and root are required to show errors from the manager');
        }
        var _b = this, uid = _b.uid, exitButton = _b.exitButton, isTvPlayer = _b.isTvPlayer;
        var ProvidedInitError = i18nProvider(this.i18nProviderConfig)(InitError);
        this.root.render(_jsx(ProvidedInitError, { uid: uid, i18nKey: i18nKey, exitButton: exitButton, isTvPlayer: isTvPlayer }));
        throw new Error('An unexpected error has been encountered.');
    };
    /**
     * Unmount the component
     */
    InitErrorManager.prototype.destroy = function () {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount();
    };
    return InitErrorManager;
}());
export default InitErrorManager;
