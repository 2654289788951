import axios from 'axios';

import { BusinessTypes } from '@canalplus/oneplayer-constants';
import {
  IMappingConfig,
  IOnlineConfig,
  TOfferLocation,
  TOfferZone,
} from '@canalplus/oneplayer-types';
import {
  isLG2019,
  isLG2020,
  isLG2021,
  isLG2022,
  isSamsung2018,
  isSamsung2019,
  isSamsung2020,
  isSamsung2021,
  isSamsung2022,
  isSamsung2023,
  removeProtocolFromUrl,
} from '@canalplus/oneplayer-utils';

const { DEVICE_TYPES } = BusinessTypes;

// TODO: This is temporary because we didnt typed the HapiConfig in the oneplayer
type THapiConfig = {
  [key: string]: any;
};

export interface IFetchContextParams {
  offerZone: string;
  domainName: string;
  context?: keyof IMappingConfig;
}

export interface IFetchOnlineConfigParams {
  context?: keyof IMappingConfig;
  env: string;
  offerLocation?: TOfferLocation;
  offerZone: TOfferZone;
  deviceType?: BusinessTypes.DEVICE_TYPES;
  configBaseUrl?: string | null;
  originFromScript?: string | null;
}

export type TSpecificConfigContext =
  | '2018'
  | '2019'
  | '2020'
  | '2021'
  | '2022'
  | '2023'
  | BusinessTypes.DEVICE_TYPES.PLAYSTATION_4
  | BusinessTypes.DEVICE_TYPES.PLAYSTATION_5
  | 'stb7'
  | 'stb8';

/**
 * @description  Determine based on detectorenv method using userAgent if we should target specific config.
 * @param deviceType - device we are on
 * @returns specific context year if it exists
 */
export function getSpecificContext(
  deviceType?: BusinessTypes.DEVICE_TYPES,
): TSpecificConfigContext | undefined {
  switch (deviceType) {
    case DEVICE_TYPES.SAMSUNG:
      switch (true) {
        case isSamsung2018():
          return '2018';
        case isSamsung2019():
          return '2019';
        case isSamsung2020():
          return '2020';
        case isSamsung2021():
          return '2021';
        case isSamsung2022():
          return '2022';
        case isSamsung2023():
          return '2023';
        default:
          return undefined;
      }
    case DEVICE_TYPES.LG:
      switch (true) {
        case isLG2019():
          return '2019';
        case isLG2020():
          return '2020';
        case isLG2021():
          return '2021';
        case isLG2022():
          return '2022';
        default:
          return undefined;
      }
    case DEVICE_TYPES.PLAYSTATION_5:
      return DEVICE_TYPES.PLAYSTATION_5;
    case DEVICE_TYPES.PLAYSTATION_4:
      return DEVICE_TYPES.PLAYSTATION_4;
    case DEVICE_TYPES.SFR_STB7_IPTV:
      return 'stb7';
    case DEVICE_TYPES.SFR_STB8_IPTV:
    case DEVICE_TYPES.SFR_STB8_CABLE:
      return 'stb8';
    default:
      return undefined;
  }
}

/**
 * @description Retrieve specif domain for config url
 * for LG and PS4 we have to use different domain for player
 * due to some issues with HTTPS certificates not recognized by those
 * devices because they are too old
 * @param deviceType - device we are on
 * @returns domain url to use
 */
export function getOnlineConfigDomainUrl(
  deviceType?: BusinessTypes.DEVICE_TYPES,
): string {
  switch (deviceType) {
    case DEVICE_TYPES.PLAYSTATION_4:
      return 'player.tv.canalplus.com';
    case DEVICE_TYPES.LG:
      return 'player.lg.canalplus.com';
    default:
      return 'player.canalplus.com';
  }
}

/**
 * @description Fetch the reference for the specific context regards context/offerZone
 * @param arg0 - params
 * @param arg0.context channel to be used as context (eg. mycanal, mycanalcos..)
 * @param arg0.offerZone the zone of the user (eg. cpafra, cppol..)
 * @param arg0.domainName the url where we fetch the config (localhost or prod)
 * @returns the correct context name as a string
 */
export async function fetchContext({
  context,
  domainName,
  offerZone,
}: IFetchContextParams): Promise<{ context: string; specific?: string[] }> {
  const configMapping = `https://${domainName}/mapping/mapping.json`;
  const { data: mapping } = await axios.get<IMappingConfig>(configMapping);
  const mappedCtx = context && mapping[context];
  if (mappedCtx === undefined || typeof mappedCtx === 'string') {
    return { context: mapping.default };
  }
  const mappedCtxOfferZone = mappedCtx[offerZone];
  if (mappedCtxOfferZone === undefined) {
    return { context: mappedCtx.default, specific: mappedCtx.specific };
  }

  return { context: mappedCtxOfferZone, specific: mappedCtx.specific };
}

/**
 * @description Fetch a config file in according to context and environment
 * @param arg0 which environment to query the configs from (prod || preprod)
 * @param arg0.context context  channel to be used as context (eg. mycanal, c8...)
 * @param arg0.env environment
 * @param arg0.offerZone geo zone
 * @param arg0.deviceType device we are on
 * @param arg0.configBaseUrl base url of the ocnfig file
 * @param arg0.originFromScript origin from script
 * @returns return the correct config file as a javascript object
 */
export async function fetchConfig({
  context,
  env,
  offerZone,
  deviceType,
  configBaseUrl,
  originFromScript,
}: IFetchOnlineConfigParams): Promise<IOnlineConfig> {
  let domainName;
  {
    // JSCRAMBLER - START
    // The following two lines
    // are here to prevent the transpilation
    // from ditching the jscrambler disable comment
    // Also we need to do this in order to keep
    // "process.env.LOCAL_CONFIG;" unencoded
    // this way it can be replaced by webpack later
    // in the oneplayer package
    // If you would use other process.env.XXX please add the same "logic"
    const check = 0;
    console.log(check); // @jscrambler disable *
    const checkConfig = process.env.LOCAL_CONFIG;
    // JSCRAMBLER - END

    // get specific url
    const domainBaseUrl =
      configBaseUrl ||
      (__DEV__ || !originFromScript
        ? getOnlineConfigDomainUrl(deviceType)
        : removeProtocolFromUrl(originFromScript));

    domainName =
      checkConfig === 'true'
        ? '127.0.0.1:3001' // Testing local config with LOCAL_CONFIG=true
        : `${domainBaseUrl}/one/configs/v2/12`; //  Prod config
  }

  // Retrieve the context based on its offerZone
  const fetchedContext = await fetchContext({
    context,
    domainName,
    offerZone,
  });

  // Another security:
  // If we can't fetch the config from its offerZone for some reasons
  // Fallback to the default context passed in params of fetchConfig
  const configContext = fetchedContext.context || context;

  // identify if there's a specific context to be fetched
  // depending on the year of the device.
  const specificContext = getSpecificContext(deviceType);

  const configSrc =
    specificContext && fetchedContext.specific?.includes(specificContext)
      ? `https://${domainName}/${configContext}/${specificContext}/${env}.json`
      : `https://${domainName}/${configContext}/${env}.json`;

  const { data: config } = await axios.get<IOnlineConfig>(configSrc);
  return config;
}

/**
 * Defined if the config file has to be fetched (again) or not
 * @param oldConfig previous config
 * @param newConfig newest config
 * @returns should we fetch a new config
 */
export function isConfigHasToBeFetched(
  oldConfig: THapiConfig,
  newConfig: THapiConfig,
): boolean {
  return (
    (newConfig.context && newConfig.context !== oldConfig.context) ||
    (newConfig.env && newConfig.env !== oldConfig.env) ||
    (newConfig.platform && newConfig.platform !== oldConfig.platform)
  );
}
