type TCurrentScript = HTMLOrSVGScriptElement & { src: string };

/**
 * @returns current script url base or null
 */
export function getBaseFromDocumentCurrentScript(): string | null {
  const { src: scriptSrc = '' } =
    (document?.currentScript as TCurrentScript) || {};
  if (scriptSrc) {
    return `${scriptSrc.split('/').slice(0, -1).join('/')}/`;
  }
  return null;
}

/**
 * @returns current script url origin
 * @link https://developer.mozilla.org/en-US/docs/Web/API/URL/origin
 */
export function getOriginFromDocumentCurrentScript(): string | null {
  const { src: scriptSrc = '' } =
    (document?.currentScript as TCurrentScript) || {};
  if (scriptSrc) {
    const url = new URL(scriptSrc);
    return url.origin;
  }
  return null;
}

export default getBaseFromDocumentCurrentScript;
