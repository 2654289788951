// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
`, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"videoIndex": `1`,
	"subtitlesIndex": `10`,
	"errorMessageIndex": `19`,
	"uiIndex": `20`,
	"progressBarFuturemask": `21`,
	"spinnerIndex": `30`,
	"backdropIndex": `31`,
	"errorIndex": `40`,
	"modalIndex": `50`,
	"modalFadeIndex": `51`,
	"contextMenuIndex": `60`,
	"miniPlayerFullFrameIndex": `70`,
	"borderRadiusTv": `4px`,
	"borderSizeExpertModeTv": `4px`
};
module.exports = ___CSS_LOADER_EXPORT___;
